import { useState, useEffect } from 'react'

import { Box, Container } from '@material-ui/core'

import { WaitingApprovalCard, DisabledCard } from './components'

import { DialogHelpDesk } from 'components'

import { useAuth } from 'hooks'

import * as service from 'service'

import { routes } from 'Routes'

import constants from 'constants/index'

const Unauthorized = () => {
  const [openDeskModal, setOpenDeskModal] = useState(false)

  const { user } = useAuth()

  const interactions = user.partner.partnerStatusInteractions

  const handleLogout = () => {
    service.marketPlace.auth.logout()
    service.history.push(routes.root)
  }

  const partnerObservation = (observation) => {
    // eslint-disable-next-line
    return observation?.replace(`{\"observations\"=>\"`, '').replace('"}', '')
  }

  useEffect(() => {
    if (
      ![
        constants.partner.WAITING_APPROVAL_STATUS,
        constants.partner.DISABLED_STATUS,
      ].includes(user.partner.status)
    ) {
      service.history.push(routes.root)
    }
    // eslint-disable-next-line
  }, [user.partner.status])

  return (
    <Container>
      <Box
        width="100%"
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {user.partner.status === constants.partner.WAITING_APPROVAL_STATUS ? (
          <WaitingApprovalCard
            handleOpenDesk={setOpenDeskModal}
            goBack={handleLogout}
          />
        ) : (
          <DisabledCard
            observation={partnerObservation(
              interactions[interactions.length - 1].observations,
            )}
            handleOpenDesk={setOpenDeskModal}
            goBack={handleLogout}
          />
        )}
      </Box>
      <DialogHelpDesk open={openDeskModal} setOpen={setOpenDeskModal} />
    </Container>
  )
}

export default Unauthorized
