import { Box, Card, Typography } from '@material-ui/core'
import { Container, ContentHeader, Page } from 'components'
import { PartnerServicesForm } from './components'

const PartnerServicesNew = () => {
  return (
    <Page title="Novo serviço">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader menu="Serviços" title="Novo" subtitle="Novo serviço" />
        <Card>
          <Box p={2}>
            <Typography variant="h5">Criar novo serviço</Typography>
          </Box>
          <Box mt={3}>
            <PartnerServicesForm />
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default PartnerServicesNew
