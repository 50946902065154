import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  logo: {
    width: '164px',
    height: '50px !important',
    marginBottom: theme.spacing(3),
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100%',
    color: theme.palette.white,
  },
  grayContainer: {
    width: '100%',
    backgroundColor: '#F4F6F8',
  },
  rootBody: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default styles
