import { useState, useEffect } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Table, TableRow, TableCell, TableBody } from 'components/Table'
import { MenuButton, ConfirmationDialog } from 'components'
import { useOnboard, useSnackbar } from 'hooks'
import { AddressDialog } from './components'
import useStyles from './styles'
import * as service from 'service'

const Address = () => {
  const [openDialogAddress, setOpenDialogAddress] = useState(false)
  const [openDialogDeleteAddress, setOpenDialogDeleteAddress] = useState(false)
  const [openDialogMainAddress, setOpenDialogMainAddress] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(null)

  const classes = useStyles()
  const { addresses, getAddresses, setToNextStep } = useOnboard()
  const snackbar = useSnackbar()

  const openDialog = (address, setState) => {
    setSelectedAddress(address)
    setState(true)
  }

  const handleDelete = async () => {
    try {
      await service.marketPlace.address.destroy(selectedAddress.id)
      await getAddresses()
      snackbar.open({
        message: 'Endereço deletado com sucesso!',
        variant: 'success',
      })
      setOpenDialogDeleteAddress(false)
    } catch (error) {
      snackbar.open({
        message: error?.response?.data?.error?.message,
        variant: 'error',
      })
    }
  }

  const handleChangeMain = async () => {
    try {
      await service.marketPlace.address.update(selectedAddress.id, {
        main: true,
      })
      await getAddresses()
      snackbar.open({
        message: 'Endereço principal atualizado!',
        variant: 'success',
      })
      setOpenDialogMainAddress(false)
    } catch (error) {
      snackbar.open({
        message: error?.response?.data?.error?.message,
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    addresses.length !== 0 ? setToNextStep(true) : setToNextStep(false)
    // eslint-disable-next-line
  }, [addresses.length])

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        className={classes.buttonsBox}
      >
        <Button
          startIcon={<PlusCircleIcon size="16" />}
          size="medium"
          variant="outlined"
          onClick={() => openDialog(null, setOpenDialogAddress)}
        >
          Adicionar
        </Button>
      </Box>
      <PerfectScrollbar options={{ suppressScrollX: false }}>
        <Table emptyMessage="Nenhum endereço encontrado" noWrap size="small">
          <TableBody>
            {addresses.map((address) => (
              <TableRow key={address.id}>
                <TableCell>
                  <Typography>
                    {address.city}/{address.state}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {address.main ? 'Endereço primário' : 'Endereço secundário'}
                  </Typography>
                </TableCell>
                <TableCell align="right" disableTooltip>
                  <MenuButton>
                    <Button
                      fullWidth
                      onClick={() => openDialog(address, setOpenDialogAddress)}
                    >
                      Editar
                    </Button>
                    {!address.main && (
                      <>
                        <Button
                          fullWidth
                          onClick={() =>
                            openDialog(address, setOpenDialogDeleteAddress)
                          }
                        >
                          Excluir
                        </Button>
                        <Button
                          fullWidth
                          onClick={() =>
                            openDialog(address, setOpenDialogMainAddress)
                          }
                        >
                          Tornar primário
                        </Button>
                      </>
                    )}
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>

      <AddressDialog
        open={openDialogAddress}
        setOpen={setOpenDialogAddress}
        address={selectedAddress}
      />
      <ConfirmationDialog
        open={openDialogDeleteAddress}
        setOpen={setOpenDialogDeleteAddress}
        title="Endereço"
        message="Deseja excluir o endereço?"
        buttonText="Excluir"
        onClick={handleDelete}
      />
      <ConfirmationDialog
        open={openDialogMainAddress}
        setOpen={setOpenDialogMainAddress}
        title="Endereço"
        message="Deseja tornar este endereço o principal?"
        buttonText="Sim"
        onClick={handleChangeMain}
      />
    </Box>
  )
}

export default Address
