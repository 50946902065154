import { useContext } from 'react'

import OnboardContext from 'contexts/OnboardContext'

const useSnackbar = () => {
  const onboardContext = useContext(OnboardContext)

  if (onboardContext === undefined) {
    throw new Error('useSnackbar must be used within a OnboardContext.Provider')
  }

  return {
    finishOnboard: onboardContext.finishOnboard,
    isLastStep: onboardContext.isLastStep,
    setIsLastStep: onboardContext.setIsLastStep,
    activeStep: onboardContext.activeStep,
    setActiveStep: onboardContext.setActiveStep,
    lastActiveStep: onboardContext.lastActiveStep,
    setLastActiveStep: onboardContext.setLastActiveStep,
    toNextStep: onboardContext.toNextStep,
    setToNextStep: onboardContext.setToNextStep,
    nextStep: onboardContext.nextStep,
    backStep: onboardContext.backStep,
    addresses: onboardContext.addresses,
    getAddresses: onboardContext.getAddresses,
    contacts: onboardContext.contacts,
    getContacts: onboardContext.getContacts,
    partnerIsCreated: onboardContext.partnerIsCreated,
    setPartnerIsCreated: onboardContext.setPartnerIsCreated,
    acceptedTerms: onboardContext.acceptedTerms,
    setAcceptedTerms: onboardContext.setAcceptedTerms,
    partner: onboardContext.partner,
    setPartner: onboardContext.setPartner,
    isPartnerReproved: onboardContext.isPartnerReproved,
  }
}

export default useSnackbar
