import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const me = async (data) => {
  return await marketPlaceAPI.get('/me', data)
}

const permissions = async () => {
  return await marketPlaceAPI.get('/user/permissions')
}

const user = {
  me,
  permissions,
}

export default user
