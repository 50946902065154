import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.main,
    width: 48,
    height: 48,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    background: theme.palette.primary.main,
  },
  completed: {
    background: theme.palette.primary.main,
  },
}))

export default styles
