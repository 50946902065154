import { usePalette } from 'react-palette'

import { Box, Avatar, Typography, Chip, Link } from '@material-ui/core'
import { ExternalLink as ExternalLinkIcon } from 'react-feather'

import { getBaseURL } from 'service/env'

import styles from './styles'
import helpers from 'helpers'

const PartnerLogoCard = ({ partner, isLoading }) => {
  const { data, loading } = usePalette(
    getBaseURL('marketPlace') + partner.logo.url,
  )
  const useStyles = styles(data.darkVibrant)

  const classes = useStyles()

  return (
    <>
      {!loading && !isLoading && (
        <>
          <Box>
            <Box height="12vh" className={classes.logoRoot}>
              <Box className={classes.logoBox}>
                <Avatar
                  src={getBaseURL('marketPlace') + partner.logo.url}
                  className={classes.logoComponent}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box>
              <Box mt={9} display="flex" justifyContent="center">
                <Typography variant="h5">{partner.name}</Typography>
              </Box>
              {partner.siteUrl && (
                <Box display="flex" justifyContent="center" mt={1}>
                  <Link
                    href={partner.siteUrl}
                    className={classes.linkContainer}
                  >
                    <Typography variant="body1">Visualizar site</Typography>
                    <ExternalLinkIcon width="15px" className={classes.icon} />
                  </Link>
                </Box>
              )}
              <Box display="flex" justifyContent="center" mt={3} mb={2}>
                <Chip
                  size="small"
                  className={helpers.partners.chipStatusColor(
                    partner.status,
                    classes,
                  )}
                  label={helpers.partners.statusLabel(partner.status)}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default PartnerLogoCard
