import {
  Box,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import logo from 'images/dponet-logo-blue.svg'

import useStyles from './styles'

const Login = ({ signUpButton, loginWithButton }) => {
  const classes = useStyles()

  return (
    <Box py={8} className={classes.root}>
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Box mt={3} mb={4} justifyContent="center" display="flex">
              <img alt="Logo" src={logo} className={classes.logo} />
            </Box>
            <Typography
              className={classes.title}
              variant="h4"
              color="textPrimary"
            >
              Acessar o DPOnet
            </Typography>
            <Box mt={3} justifyContent="center" display="flex">
              <Button
                className={classes.bePartnerButton}
                onClick={() => loginWithButton()}
              >
                <Typography className={classes.buttonText}>
                  Acessar com OneID
                </Typography>
              </Button>
            </Box>
            <Box mt={3} className={classes.linkBox}>
              <Typography className={classes.LinkText}>
                Você não tem uma conta?
              </Typography>
              <Typography
                className={classes.Link}
                onClick={() => signUpButton()}
              >
                cadastrar uma nova conta
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}

Login.propTypes = {
  signUpButton: PropTypes.func,
  loginWithButton: PropTypes.func,
}

Login.defaultProps = {
  signUpButton: () => {},
  loginWithButton: () => {},
}

export default Login
