import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'

import { SuccessButton } from 'components'

const BaseActionDialog = ({
  title,
  infoMessage,
  actionButtonText,
  open,
  maxWidth = 'md',
  isLoading = false,
  fullScreen = false,
  action = () => {},
  setOpen = () => {},
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={maxWidth}
      fullWidth
      disableBackdropClick
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h5">{title}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <Typography align="justify" variant="body1" color="secondary">
            {infoMessage}
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box px={2} display="flex" justifyContent="flex-end" width="100%">
          <Box pl={2} pr={1}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              disabled={isLoading}
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Box>
          <SuccessButton
            variant="contained"
            onClick={action}
            disabled={isLoading}
          >
            {actionButtonText}
          </SuccessButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

BaseActionDialog.prototype = {
  title: PropTypes.string.isRequired,
  infoMessage: PropTypes.string.isRequired,
  actionButtonText: PropTypes.string.isRequired,
  open: PropTypes.bool,
  action: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default BaseActionDialog
