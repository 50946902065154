import api from 'service/api'
import * as service from 'service'
import axios from 'axios'

import { getBaseURL } from 'service/env'

const baseURL = getBaseURL('marketPlace')

const marketPlaceAPI = api.create('marketPlace', false)

const create = async (params) => {
  const response = await marketPlaceAPI.post('/partners', params)

  return response
}

const update = async (params) => {
  const response = await marketPlaceAPI.put('/partners', params)

  return response
}

const addLogo = async (logo) => {
  var logoFormData = new FormData()

  logoFormData.append('logo', logo)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.marketPlace.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/partners/logo`,
    data: logoFormData,
    ...config,
  })
}
const partner = {
  create,
  update,
  addLogo,
}

export default partner
