import { useState } from 'react'
import useSnackbar from 'hooks/useSnackbar'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import {
  LoadingBox,
  BaseActionDialog as DeleteContactDialog,
  BaseActionDialog as ChangeToMainDialog,
  MenuButton,
} from 'components'
import {
  DetailsContactDialog,
  NewContactDialog,
  UpdateContactDialog,
} from './components'
import helpers from 'helpers'
import * as service from 'service'

const TableRowContact = ({
  contact,
  handleDetails = () => {},
  handleDelete = () => {},
  handleUpdate = () => {},
  handleMakePrimary = () => {},
  ...rest
}) => {
  return (
    <TableRow size="large" {...rest}>
      <TableCell overFlowSize="92px">{contact.id}</TableCell>
      <TableCell overFlowSize="276px">{contact.email || '-'}</TableCell>
      <TableCell overFlowSize="276px">
        {helpers.formatters.phone(contact.phone) || '-'}
      </TableCell>
      <TableCell overFlowSize="276px">
        {contact.main ? 'Contato primário' : 'Contato secundário'}
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            fullWidth
            onClick={() => {
              handleDetails(contact)
            }}
          >
            Detalhes
          </Button>
          <Button fullWidth onClick={() => handleUpdate(contact)}>
            Editar
          </Button>
          {!contact.main && (
            <>
              <Button fullWidth onClick={() => handleDelete(contact)}>
                Excluir
              </Button>
              <Button fullWidth onClick={() => handleMakePrimary(contact)}>
                Tornar primário
              </Button>
            </>
          )}
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const ContactsTable = ({ contacts, fullScreen, onAction = () => {} }) => {
  const snackbar = useSnackbar()
  const [currentContact, setCurrentContact] = useState(null)
  const [detailsContactDialog, setDetailsContactDialog] = useState(false)
  const [newContactDialog, setNewContactDialog] = useState(false)
  const [updateContactDialog, setUpdateContactDialog] = useState(false)
  const [deleteContactDialog, setDeleteContactDialog] = useState(false)
  const [changeToMainDialog, setChangeToMainDialog] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleDetails = (contact) => {
    setCurrentContact(contact)
    setDetailsContactDialog(true)
  }

  const handleUpdate = (contact) => {
    setCurrentContact(contact)
    setUpdateContactDialog(true)
  }

  const handleOpenDelete = (contact) => {
    setCurrentContact(contact)
    setDeleteContactDialog(true)
  }

  const handleOpenChangeToMain = (contact) => {
    setCurrentContact(contact)
    setChangeToMainDialog(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await service.marketPlace.contact.destroy(currentContact?.id)
      snackbar.open({
        message: 'Contato excluído com sucesso',
        variant: 'success',
      })
      setDeleteContactDialog(false)
      onAction()
      setLoading(false)
    } catch (error) {
      handleError(error)
    }
  }

  const handleChangeToMain = async () => {
    setLoading(true)

    try {
      await service.marketPlace.contact.update(currentContact?.id, {
        main: true,
      })
      setChangeToMainDialog(false)
      onAction()
      setLoading(false)
    } catch (error) {
      handleError(error)
    }
  }

  const handleError = (error) => {
    setLoading(false)
    snackbar.open({
      message: helpers.formatters.errorMessage(error),
      variant: 'error',
    })
  }

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <Box width="100%" p={2}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Contatos</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" width="100%" justifyContent="flex-end">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setNewContactDialog(true)
                    }}
                  >
                    Novo contato
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box width="100%">
            <PerfectScrollbar
              options={{ wheelPropagation: false, useBothWheelAxes: true }}
            >
              <Table
                size="small"
                emptyMessage="Nenhum contato encontrado"
                noWrap
              >
                <TableHead>
                  <TableRow height="55px">
                    <TableCell width="10%">Identificador</TableCell>
                    <TableCell width="40%">Email</TableCell>
                    <TableCell width="30%">Telefone</TableCell>
                    <TableCell width="20%">Tipo de contato</TableCell>
                    <TableCell width="10%" align="right">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts?.map((contact) => (
                    <TableRowContact
                      contact={contact}
                      key={contact.id}
                      handleDetails={handleDetails}
                      handleUpdate={handleUpdate}
                      handleDelete={handleOpenDelete}
                      handleMakePrimary={handleOpenChangeToMain}
                      hover
                    />
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Box>
        </>
      )}
      <DetailsContactDialog
        contact={currentContact}
        open={detailsContactDialog}
        setOpen={setDetailsContactDialog}
        fullScreen={fullScreen}
      />
      <NewContactDialog
        open={newContactDialog}
        setOpen={setNewContactDialog}
        onAction={onAction}
        fullScreen={fullScreen}
      />
      <UpdateContactDialog
        open={updateContactDialog}
        setOpen={setUpdateContactDialog}
        contact={currentContact}
        onAction={onAction}
        fullScreen={fullScreen}
      />
      <DeleteContactDialog
        open={deleteContactDialog}
        setOpen={setDeleteContactDialog}
        title="Excluir contato"
        isLoading={isLoading}
        infoMessage="Deseja realmente excluir este contato? Esta ação é irreversível."
        actionButtonText="Excluir"
        maxWidth="sm"
        action={handleDelete}
        fullScreen={fullScreen}
      />
      <ChangeToMainDialog
        open={changeToMainDialog}
        setOpen={setChangeToMainDialog}
        title="Alterar contato para primário"
        isLoading={isLoading}
        infoMessage="Deseja realmente definir este contato como primário?"
        actionButtonText="Definir como primário"
        maxWidth="sm"
        fullScreen={fullScreen}
        action={handleChangeToMain}
      />
    </>
  )
}

export default ContactsTable
