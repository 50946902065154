import { useState } from 'react'

import * as service from 'service'
import SelectOptionsContext from 'contexts/SelectOptionsContext'

const SelectOptionsProvider = ({ children }) => {
  const [companyServices, setCompanyServices] = useState(null)
  const [unformattedCompanyServices, setUnformattedCompanyServices] =
    useState(null)
  const [isLoadingOptions, setLoadingOptions] = useState(true)
  const [reload, setReload] = useState(0)

  const formatOptionsForAutocomplete = (options) => {
    return [
      ...options.map((option) => {
        return { value: option.id, label: option.name }
      }),
    ]
  }

  const loadData = async (companyServiceParams) => {
    const [responseCompanyServices] = await Promise.all([
      service.marketPlace.companyService.get(companyServiceParams),
    ])

    setCompanyServices(
      formatOptionsForAutocomplete(
        responseCompanyServices?.data?.companyServices,
      ),
    )

    setUnformattedCompanyServices(
      responseCompanyServices?.data?.companyServices,
    )

    setLoadingOptions(false)
  }

  return (
    <SelectOptionsContext.Provider
      value={{
        companyServices,
        loadData,
        setReload,
        reload,
        isLoadingOptions,
        unformattedCompanyServices,
        formatOptionsForAutocomplete,
      }}
    >
      {children}
    </SelectOptionsContext.Provider>
  )
}

export default SelectOptionsProvider
