import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ScreenLoading } from 'components'
import { SnackbarProvider } from 'providers'

const AnimatedLoading = ({ children, ...rest }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 200)
  })

  return (
    <ScreenLoading loaded={loaded}>
      <SnackbarProvider>{children}</SnackbarProvider>
    </ScreenLoading>
  )
}

AnimatedLoading.propTypes = {
  children: PropTypes.any,
}

export default AnimatedLoading
