import React from 'react'
import clsx from 'clsx'
import { Box } from '@material-ui/core'
import {
  User as UserIcon,
  Home as HomeIcon,
  Phone as PhoneIcon,
  FileText as FileTextIcon,
} from 'react-feather'

import useStyles from './styles'

function StepIcon({ active, completed, icon }) {
  const classes = useStyles()

  const icons = {
    1: <UserIcon color="#FFF" size="20" />,
    2: <HomeIcon color="#FFF" size="20" />,
    3: <PhoneIcon color="#FFF" size="20" />,
    4: <FileTextIcon color="#FFF" size="20" />,
  }

  return (
    <Box
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[icon]}
    </Box>
  )
}

export default StepIcon
