import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import { Label, LoadingBox, SuccessButton, MoneyInput } from 'components'
import { DocumentsTable } from '../'
import { DollarSign as DollarSignIcon } from 'react-feather'
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { Save as SaveIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import schema from './schema'
import * as service from 'service'
import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'

const PartnerServicesForm = ({ partnerServiceId, ...props }) => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const [id, setId] = useState(partnerServiceId)
  const [partnerService, setPartnerService] = useState(props?.partnerService)
  const [selectedCompanyService, setSelectedCompanyService] = useState(
    partnerService?.companyService
      ? {
          value: partnerService.companyService.id,
          label: partnerService.companyService.name,
        }
      : null,
  )
  const [selectedServiceCategory, setSelectedServiceCategory] = useState(
    partnerService?.companyService?.serviceCategory
      ? {
          value: partnerService?.companyService?.serviceCategory.id,
          label: partnerService?.companyService?.serviceCategory.name,
        }
      : null,
  )
  const [loading, setLoading] = useState(false)
  const [free, setFree] = useState(partnerService?.free ?? false)
  const [price, setPrice] = useState(partnerService?.price ?? 0)
  const [documents, setDocuments] = useState(partnerService?.documents)
  const [companyServices, setCompanyServices] = useState([])
  const [serviceCategories, setServiceCategories] = useState([])
  const [unformattedCompanyServices, setUnformattedCompanyServices] = useState(
    [],
  )
  const [unformattedServicesCategories, setUnformattedServicesCategories] =
    useState([])

  const { control, handleSubmit, errors, reset, setValue, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      serviceModality: props?.partnerService?.serviceModality || '',
      serviceCategory: props?.partnerService?.companyService?.serviceCategory
        ? {
            value:
              props?.partnerService?.companyService?.serviceCategory?.id || '',
            label:
              props?.partnerService?.companyService?.serviceCategory?.name ||
              '',
          }
        : null,
      companyServiceId: {
        value: props?.partnerService?.companyService?.id || '',
        label: props?.partnerService?.companyService?.name || '',
      },
      description: partnerService?.description || '',
    },
  })

  const formatOptionsForAutocomplete = (options) => {
    return [
      ...options.map((option) => {
        return { value: option.id, label: option.name }
      }),
    ]
  }
  const loadCompanyServices = async () => {
    const serviceCategoryId =
      selectedServiceCategory?.id ||
      props?.partnerService?.companyService?.serviceCategory?.id

    const responseCompanyServices =
      await service.marketPlace.companyService.get({
        serviceCategoryId: serviceCategoryId,
      })

    setCompanyServices(
      formatOptionsForAutocomplete(
        responseCompanyServices?.data?.companyServices,
      ),
    )

    setUnformattedCompanyServices(
      responseCompanyServices?.data?.companyServices,
    )
  }

  useEffect(() => {
    const loadCategories = async () => {
      setLoading(true)
      const responseServiceCategories =
        await service.marketPlace.serviceCategories.get({})

      setServiceCategories(
        formatOptionsForAutocomplete(
          responseServiceCategories?.data?.serviceCategories,
        ),
      )
      setUnformattedServicesCategories(
        responseServiceCategories?.data?.serviceCategories,
      )

      if (props?.partnerService) {
        await loadCompanyServices()
      }
      setLoading(false)
    }

    loadCategories()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (watch('serviceCategory')) {
      loadCompanyServices()
    }
    // eslint-disable-next-line
  }, [watch('serviceCategory')])

  const serviceCategory = partnerService?.companyService?.serviceCategory
    ? {
        value: partnerService.companyService.serviceCategory.id,
        label: partnerService.companyService.serviceCategory.name,
      }
    : null

  const companyService = partnerService?.companyService
    ? {
        value: partnerService.companyService.id,
        label: partnerService.companyService.name,
      }
    : null

  const onSubmit = (partnerService) => {
    setLoading(true)

    if (id) {
      handleEdit(partnerService)
    } else {
      handleCreate(partnerService)
    }
  }

  const handleCreate = async (data) => {
    try {
      let response = await service.marketPlace.partnerService.create({
        partnerService: {
          ...data,
          companyServiceId: data.companyServiceId.value,
          free,
        },
      })
      setId(response?.data?.partnerService?.id)
      setPartnerService(response?.data?.partnerService)

      await handleCreateDocuments(response?.data?.partnerService?.id, data)

      setLoading(false)
      snackbar.open({
        message: 'Serviço de empresa criado com sucesso',
        variant: 'success',
      })
      history.push(routes.services.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleEdit = async (data) => {
    try {
      if (
        partnerService.status !==
        constants.partnerService.WAITING_APPROVAL_STATUS
      ) {
        await service.marketPlace.partnerService.update({
          partnerServiceId: id,
          partnerService: {
            ...data,
            price: data?.price || 0,
            free,
          },
        })
      }

      await handleCreateDocuments(id, data)
      setLoading(false)
      snackbar.open({
        message: 'Serviço de empresa atualizado com sucesso',
        variant: 'success',
      })
      history.push(routes.services.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleCreateDocuments = async (partnerServiceId, data) => {
    if (!documents) return

    const files = documents.filter((document) => {
      return !document?.url
    })

    if (!files) return

    await service.marketPlace.partnerService.addDocument({
      partnerServiceId,
      files,
    })
  }

  const handleError = (error, data) => {
    setLoading(false)
    reset(data)
    snackbar.open({
      message: helpers.formatters.errorMessage(error),
      variant: 'error',
    })
  }

  const handleChangeCheckBox = (event) => {
    const checked = event.target.checked

    if (checked) {
      setValue('price', 0)
    } else {
      setValue('price', price)
    }

    setFree(checked)
  }

  const handleChangeCompanyService = (value) => {
    let option = unformattedCompanyServices.find(
      (element) => element.name === value,
    )
    setSelectedCompanyService(option)
  }

  const handleChangeServiceCategory = (value) => {
    let option = unformattedServicesCategories.find(
      (element) => element.name === value,
    )
    setSelectedServiceCategory(option)
  }

  const handleChangeDocuments = () => {
    const documentInput = document.getElementById('documents')
    const files = documentInput.files
    if (documents) {
      setDocuments([...documents, ...files])
    } else {
      setDocuments([...files])
    }

    documentInput.value = ''
  }

  const handleRemoveDocument = async (document, index) => {
    var docs = documents
    setLoading(true)
    if (id && document.url) {
      const params = {
        documentName: helpers.formatters.documentName(document?.url),
      }

      try {
        await service.marketPlace.partnerService.deleteDocument({
          partnerServiceId: partnerService?.id,
          params,
        })
        docs.splice(index, 1)
      } catch {
        snackbar.open({
          message: 'Falha ao remover documento!',
          variant: 'error',
        })
        setLoading(false)
      }
    } else {
      docs.splice(index, 1)
    }

    setDocuments([...docs])
    setLoading(false)
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%" px={3} pb={10}>
            <Grid container spacing={4}>
              {!isEmpty(serviceCategories) && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <Autocomplete
                        disabled={serviceCategory !== null}
                        options={serviceCategories?.map((val) => val)}
                        defaultValue={
                          serviceCategory || selectedServiceCategory
                        }
                        getOptionLabel={(option) =>
                          option.label ? option.label : ''
                        }
                        getOptionSelected={(option, value) =>
                          option.value === value?.value ||
                          value?.value === serviceCategory?.value ||
                          value?.value === selectedServiceCategory?.value
                        }
                        noOptionsText="Nenhum novo serviço disponível no momento."
                        onInputChange={(event, newInputValue) => {
                          handleChangeServiceCategory(newInputValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={!!errors?.serviceCategory}
                            helperText={
                              errors?.serviceCategory
                                ? 'Selecione uma categoria'
                                : ''
                            }
                            label="Categoria de serviços"
                          />
                        )}
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="serviceCategory"
                    onChange={([, data]) => data}
                    mode="onChange"
                  />
                </Grid>
              )}
              {!isEmpty(companyServices) && !isEmpty(watch('serviceCategory')) && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <Autocomplete
                        disabled={companyService !== null}
                        defaultValue={companyService}
                        options={companyServices?.map((val) => val)}
                        getOptionLabel={(option) =>
                          option.label ? option.label : ''
                        }
                        getOptionSelected={(option, value) =>
                          option.value === value?.value ||
                          value?.value === companyService?.value
                        }
                        noOptionsText="Nenhum novo serviço disponível no momento."
                        onInputChange={(event, newInputValue) => {
                          handleChangeCompanyService(newInputValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={!!errors?.companyServiceId}
                            helperText={
                              errors?.companyServiceId
                                ? 'Selecione um serviço'
                                : ''
                            }
                            label="Serviço da empresa"
                          />
                        )}
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="companyServiceId"
                    onChange={([, data]) => data}
                    mode="onChange"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="serviceModality"
                  as={
                    <TextField
                      select
                      color="primary"
                      variant="outlined"
                      label="Modalidade do serviço"
                      error={!!errors.serviceModality}
                      helperText={errors?.serviceModality?.message}
                      fullWidth
                    >
                      {Object.keys(
                        constants.partnerService.SERVICE_MODALITY,
                      ).map((service_modality_key) => (
                        <MenuItem value={service_modality_key}>
                          <Typography>
                            {
                              constants.partnerService.SERVICE_MODALITY[
                                service_modality_key
                              ]
                            }
                          </Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  mode="onBlur"
                />
              </Grid>
              {selectedCompanyService && (
                <Grid item xs={12}>
                  <Label
                    title="Exigências especificas para este serviço"
                    titleVariant="h5"
                  >
                    <Typography
                      variant="body1"
                      color="secondary"
                      align="justify"
                    >
                      {selectedCompanyService?.requirements}
                    </Typography>
                  </Label>
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      error={!!errors.description}
                      helperText={errors?.description?.message}
                      minRows={8}
                      label="Descreva suas capacitações para este serviço."
                    />
                  }
                  control={control}
                  name="description"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="secondary">
                  Anexe documentos ou certificados que comprovem sua aptidão
                  para prestar este serviço.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="documents"
                  control={control}
                  onChange={handleChangeDocuments}
                  as={
                    <Button
                      variant="outlined"
                      htmlFor="documents"
                      component="label"
                    >
                      <Typography>Selecionar documento(s)</Typography>
                      <input
                        type="file"
                        multiple
                        id="documents"
                        style={{ display: 'none' }}
                      />
                    </Button>
                  }
                />
              </Grid>
              {documents && (
                <Grid item xs={12}>
                  <DocumentsTable
                    documents={documents}
                    partnerServiceId={id}
                    handleRemoveDocument={handleRemoveDocument}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="price"
                  as={
                    <TextField
                      label="Preço do Serviço"
                      type="text"
                      color="primary"
                      variant="outlined"
                      defaultValue={partnerService?.price ?? ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DollarSignIcon />
                          </InputAdornment>
                        ),
                        inputComponent: MoneyInput,
                      }}
                      fullWidth
                      error={!!errors.price}
                      helperText={errors?.price?.message}
                      disabled={free}
                    />
                  }
                  control={control}
                  mode="onBlur"
                  onChange={([data]) => {
                    setPrice(data)
                    return data
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={free}
                      onChange={handleChangeCheckBox}
                      name="free"
                      color="primary"
                    />
                  }
                  label="Gratuito?"
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box py={2} px={2} display="flex" justifyContent="flex-end">
            <Box pr={1}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={history.goBack}
              >
                Cancelar
              </Button>
            </Box>
            <SuccessButton
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Salvar
            </SuccessButton>
          </Box>
        </form>
      )}
    </>
  )
}

PartnerServicesForm.propTypes = {
  partnerServiceId: PropTypes.string,
}

export default PartnerServicesForm
