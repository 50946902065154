import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required(),
  tradeName: yup.string().required(),
  description: yup.string().required(),
  document: yup.string().required(),
  documentType: yup.string().required(),
  kind: yup.string().required(),
  protocol: yup.string(),
  siteUrl: yup.string(),
})

export default schema
