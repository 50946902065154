import { useState } from 'react'
import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router'

import { Box, Button, Typography, Divider, Grid } from '@material-ui/core'

import {
  Label,
  BaseActionDialog as ChangeStatusDialog,
  LogStatusDialog,
  MenuButton,
} from 'components'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'
import { routes } from 'Routes'

const PartnerDetails = ({
  partner,
  isLoading,
  refresh = () => {},
  setLoading = () => {},
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const [changeStatusDialog, setChangeStatusDialog] = useState(false)
  const [logStatusDialog, setLogStatusDialog] = useState(false)

  const handleChangeStatus = async () => {
    setLoading(true)

    let data = {
      status: constants.partner.CHANGE_STATUS_VALUE[partner?.status],
    }

    try {
      await service.marketPlace.partner.update(data)
      setChangeStatusDialog(false)
      snackbar.open({
        message:
          constants.partner.CHANGE_STATUS_SUCCESS_MESSAGES[partner?.status],
        variant: 'success',
      })
      refresh()
    } catch (error) {
      handleError(error)
    }
  }

  const handleError = (error) => {
    setLoading(false)
    snackbar.open({
      message: helpers.formatters.errorMessage(error),
      variant: 'error',
    })
  }

  const handleEdit = () => {
    history.push(routes.myInformation.edit)
  }

  const showActionButtons = () => {
    return (
      partner?.status === constants.partner.ACTIVE_STATUS ||
      partner?.status === constants.partner.INACTIVE_STATUS
    )
  }

  return (
    <Box width="100%">
      <>
        {!isLoading && (
          <>
            <Box px={2}>
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">Meus Dados</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box width="100%" display="flex" justifyContent="flex-end">
                    <MenuButton>
                      {showActionButtons() && (
                        <>
                          <Button fullWidth onClick={() => handleEdit()}>
                            Editar
                          </Button>
                          <Button
                            fullWidth
                            onClick={() => setChangeStatusDialog(true)}
                          >
                            {
                              constants.partner
                                .CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT[
                                partner?.status
                              ]
                            }
                          </Button>
                        </>
                      )}
                      <Button
                        fullWidth
                        onClick={() => setLogStatusDialog(true)}
                      >
                        Histórico de Alterações
                      </Button>
                    </MenuButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box px={2} py={1}>
              <Grid container spacing={5}>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <Label title="Nome" titleVariant="h6">
                    <Typography
                      variant="body1"
                      color="secondary"
                      align="justify"
                    >
                      {partner.name}
                    </Typography>
                  </Label>
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <Label title="Nome Fantasia" titleVariant="h6">
                    <Typography
                      variant="body1"
                      color="secondary"
                      align="justify"
                    >
                      {partner.tradeName || '-'}
                    </Typography>
                  </Label>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <Label title={partner.documentType} titleVariant="h6">
                    <Typography
                      variant="body1"
                      color="secondary"
                      align="justify"
                    >
                      {partner.documentType === 'CPF'
                        ? helpers.formatters.cpf(partner.document)
                        : helpers.formatters.cnpj(partner.document)}
                    </Typography>
                  </Label>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Label
                    title="Data do aceite dos termos de uso"
                    titleVariant="h6"
                  >
                    <Typography
                      variant="body1"
                      color="secondary"
                      align="justify"
                    >
                      {helpers.formatters.date(partner?.termsAcceptedAt) ||
                        'Não aceito'}
                    </Typography>
                  </Label>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Label title="Visualizações" titleVariant="h6">
                    <Typography
                      variant="body1"
                      color="secondary"
                      align="justify"
                    >
                      {partner?.views}
                    </Typography>
                  </Label>
                </Grid>
                <Grid item xs={12}>
                  <Label title="Descrição" titleVariant="h6">
                    <Typography
                      variant="body1"
                      color="secondary"
                      align="justify"
                    >
                      {partner?.description}
                    </Typography>
                  </Label>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </>
      <ChangeStatusDialog
        title={constants.partner.CHANGE_STATUS_DIALOG_TITLES[partner?.status]}
        infoMessage={
          constants.partner.CHANGE_STATUS_DIALOG_INFO_MESSAGES[partner?.status]
        }
        actionButtonText={
          constants.partner.CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT[
            partner?.status
          ]
        }
        open={changeStatusDialog}
        maxWidth="sm"
        setOpen={setChangeStatusDialog}
        action={handleChangeStatus}
      />
      <LogStatusDialog
        logs={partner?.partnerStatusInteractions}
        open={logStatusDialog}
        setOpen={setLogStatusDialog}
      />
    </Box>
  )
}

export default PartnerDetails
