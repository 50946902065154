import * as yup from 'yup'

const schema = yup.object().shape({
  description: yup.string(),
  street: yup.string().required(),
  number: yup.string().required(),
  neighborhood: yup.string().required(),
  complement: yup.string(),
  postalCode: yup.string().required(),
  state: yup.string().max(2).required(),
  country: yup.string().required(),
  city: yup.string().required(),
})

export default schema
