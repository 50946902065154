import updateStatus from './updateStatus'
import labelTextButton from './labelTextButton'
import chipStatusColor from './chipStatusColor'
import statusLabel from './statusLabel'

const orders = {
  updateStatus,
  labelTextButton,
  chipStatusColor,
  statusLabel,
}

export default orders
