import { useState } from 'react'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Divider,
  Card,
  Grid,
  Tab,
  Tabs,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  Page,
} from 'components'

import { OrdersTable } from './components'

import constants from 'constants/index'
import * as service from 'service'

const OrdersMain = () => {
  const filter = useFilter()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const [currentTab, setCurrentTab] = useState(
    constants.order.status.ALL_STATUS,
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.marketPlace.orders.get,
    {
      ...{ ...filter.filters },
      status: currentTab,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
    filter.setFilters({ ...filter.filters, status: value })
  }

  return (
    <Page title="Todas as solicitações">
      <Container maxWidth={false} spacingXl={30} spacingLg={10}>
        <ContentHeader menu="Solicitações" title="Listagem" subtitle="Todos">
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Tabs
          scrollButtons="on"
          onChange={handleChangeTabs}
          textColor="primary"
          indicatorColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {constants.order.TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        <Divider />
        <Box mt={3}>
          <Card>
            <Box display="flex" width="100%" p={0}>
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">
                      Solicitações de serviços
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box display="flex" width="100%">
              <Grid container>
                <OrdersTable
                  orders={response?.data?.orders}
                  isLoading={isLoading || loading}
                  onEvent={refresh}
                  setLoading={setLoading}
                  currentTab={currentTab}
                />
              </Grid>
            </Box>
            <Box px={2} display="flex" justifyContent="flex-end">
              {!isLoading || loading ? (
                <TablePagination
                  component="div"
                  count={response?.data?.meta?.totalCount}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              ) : (
                <Box py="11px">
                  <Skeleton variant="rect" width={200} height={30} />
                </Box>
              )}
            </Box>
          </Card>
        </Box>
        {!isLoading && (
          <Filters filter={filter}>
            <input textfieldinput="true" label="Identificador" name="id" />
            <input
              datepickerinput="true"
              label="Data de solicitação"
              name="createdAtGt"
              data-target="createdAtLt"
              data-min
            />
          </Filters>
        )}
      </Container>
    </Page>
  )
}

export default OrdersMain
