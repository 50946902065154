import { Chip, Grid, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Label } from 'components'

import useStyles from './styles'
import helpers from 'helpers'
import constants from 'constants/index'

const PartnerServiceDetails = ({ partnerService }) => {
  const classes = useStyles()

  const partnerServiceObservation = (observation) => {
    // eslint-disable-next-line
    return observation?.replace(`{\"observations\"=>\"`, '').replace('"}', '')
  }

  const reproveObservations = partnerServiceObservation(
    partnerService.partnerServiceStatusInteractions[
      partnerService.partnerServiceStatusInteractions.length - 1
    ]?.observations,
  )

  return (
    <Grid container spacing={5}>
      {partnerService.status === constants.partnerService.REPROVED_STATUS && (
        <Grid item xs={12}>
          <Alert severity="error">
            <AlertTitle>Motivo da reprova</AlertTitle>
            <Typography color="secondary" align="justify">
              {reproveObservations}
            </Typography>
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Label title="Nome do serviço" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {partnerService.companyService.name}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Label title="Status" titleVariant="h5">
          <Chip
            size="small"
            className={helpers.partnerServices.chipStatusColor(
              partnerService.status,
              classes,
            )}
            label={helpers.partnerServices.statusName(partnerService.status)}
          />
        </Label>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Label title="Preço" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            R$ {partnerService.price.toFixed(2)}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Label title="modalidade" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {
              constants.partnerService.SERVICE_MODALITY[
                partnerService.serviceModality
              ]
            }
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Label title="Data de Criação" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {helpers.formatters.date(partnerService.createdAt)}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12}>
        <Label title="Descrição do serviço" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {partnerService.companyService.description}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12}>
        <Label
          title="Breve descrição sobre suas capacitações para prestar este serviço"
          titleVariant="h5"
        >
          <Typography variant="body1" color="secondary" align="justify">
            {partnerService.description}
          </Typography>
        </Label>
      </Grid>
    </Grid>
  )
}

export default PartnerServiceDetails
