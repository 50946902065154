import cpf from './cpf'
import cnpj from './cnpj'
import cep from './cep'
import date from './date'
import errorMessage from './errorMessage'
import phone from './phone'
import convertStringToInteger from './convertStringToInteger'
import hours from './hours'
import documentName from './documentName'

const formatters = {
  cpf,
  cnpj,
  cep,
  date,
  errorMessage,
  phone,
  hours,
  convertStringToInteger,
  documentName,
}

export default formatters
