import { useState } from 'react'
import useSnackbar from 'hooks/useSnackbar'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'

import { SuccessButton, LoadingBox } from 'components'
import AddressForm from '../AddressForm'

import helpers from 'helpers'

import * as service from 'service'

const NewAddressDialog = ({
  open,
  fullScreen = false,
  setOpen = () => {},
  onAction = () => {},
}) => {
  const snackbar = useSnackbar()
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async (data) => {
    setLoading(true)

    try {
      await service.marketPlace.address.create(data)
      snackbar.open({
        message: 'Endereço criado com sucesso!',
        variant: 'success',
      })
      onAction()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h5">Novo endereço</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <DialogContent>
              <Box my={2}>
                <AddressForm onSubmit={handleSubmit} />
              </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Box px={2} display="flex" justifyContent="flex-end" width="100%">
                <Box pl={2} pr={1}>
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpen(false)}
                  >
                    Fechar
                  </Button>
                </Box>
                <SuccessButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  form="address-form"
                >
                  Criar
                </SuccessButton>
              </Box>
            </DialogActions>
          </>
        )}
      </>
    </Dialog>
  )
}

export default NewAddressDialog
