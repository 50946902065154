import { include } from 'named-urls'

import { Switch, Redirect, Route } from 'react-router-dom'
import { RouteWithLayout } from './components'

import { Main as MainLayout, Onboard as OnboardLayout } from './layouts'

import {
  LandingPage,
  NotFound,
  Forbidden,
  Home,
  Onboard,
  PartnerService,
  MyInformation,
  User,
  Order,
} from 'views'

import SnackbarProvider from 'providers/SnackbarProvider'
import SelectOptionsProvider from 'providers/SelectOptionsProvider'
import MyInformationsProvider from 'providers/MyInformationProvider'

import constants from 'constants/index'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path={routes.root}
        component={LandingPage}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.home}
        component={Home}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.onboard}
        component={Onboard}
        layout={OnboardLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.services.all}
        component={PartnerService.Main}
        permission={constants.permissions.SERVICES.LIST}
        provider={SelectOptionsProvider}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.services.new}
        component={PartnerService.New}
        permissions={constants.permissions.SERVICES.CREATE}
        provider={SelectOptionsProvider}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.services.edit}
        component={PartnerService.Edit}
        permissions={constants.permissions.SERVICES.UPDATE}
        provider={SelectOptionsProvider}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.services.show}
        component={PartnerService.Show}
        permissions={constants.permissions.SERVICES.SHOW}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.myInformation.show}
        component={MyInformation.Show}
        layout={MainLayout}
        provider={MyInformationsProvider}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.myInformation.edit}
        component={MyInformation.Edit}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.orders.all}
        component={Order.Main}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.users.unauthorized}
        component={User.Unauthorized}
        layout={OnboardLayout}
        animatedLoading
        exact
        auth
      />

      <Route component={NotFound} exact path={routes.notFound} />
      <Route component={Forbidden} exact path={routes.forbidden} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}

export const routes = {
  root: '/',

  home: '/home',

  onboard: '/onboard',

  myInformation: include('/my-information', {
    show: '',
    edit: 'edit',
  }),

  services: include('/services', {
    all: '',
    new: 'new',
    edit: ':serviceId/edit',
    show: ':serviceId/show',
  }),

  users: include('/users', {
    all: '',
    unauthorized: 'unauthorized',
  }),

  orders: include('/orders', {
    all: '',
  }),

  notFound: '/not-found',
  forbidden: '/forbidden',
}

export default Routes
