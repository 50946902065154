const formatPrice = (partnerService) => {
  if (partnerService.free) {
    return 'grátis'
  }

  if (partnerService.price === 0) {
    return 'a combinar'
  }

  if (partnerService.price > 0) {
    return `R$ ${partnerService.price.toFixed(2)}`
  }
}

export default formatPrice
