import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.partner.ACTIVE_STATUS:
      return 'ATIVO'
    case constants.partner.INACTIVE_STATUS:
      return 'INATIVO'
    case constants.partner.ONBOARD_STATUS:
      return 'ONBOARD'
    case constants.partner.REPROVED_STATUS:
      return 'REPROVADO'
    case constants.partner.WAITING_APPROVAL_STATUS:
      return 'AGUARDANDO APROVAÇÃO'
    case constants.partner.DISABLED_STATUS:
      return 'DESABILITADO'
    default:
      return '-'
  }
}

export default statusLabel
