import { Button } from '@material-ui/core'

import useStyles from './styles'

const SuccessButton = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Button className={classes.successButton} {...rest}>
      {children}
    </Button>
  )
}

export default SuccessButton
