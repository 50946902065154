import { TextField, Grid, Box } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import schema from './schema'
import helpers from 'helpers'

const AddressForm = ({ onSubmit, address }) => {
  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      description: address?.description ?? '',
      street: address?.street ?? '',
      number: address?.number ?? '',
      neighborhood: address?.neighborhood ?? '',
      complement: address?.complement ?? '',
      postalCode: helpers.formatters.cep(address?.postalCode) ?? '',
      state: address?.state ?? '',
      country: address?.country ?? '',
      city: address?.city ?? '',
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="address-form">
      <Controller
        control={control}
        name="country"
        as={
          <TextField
            label="País"
            color="primary"
            variant="outlined"
            error={!!errors.country}
            helperText={errors?.country?.message}
            fullWidth
          />
        }
        mode="onBlur"
      />
      <Box mt={2}>
        <Controller
          control={control}
          name="postalCode"
          as={
            <TextField
              label="CEP"
              color="primary"
              variant="outlined"
              error={!!errors.postalCode}
              helperText={errors?.postalCode?.message}
              fullWidth
            />
          }
          onChange={([text]) => helpers.formatters.cep(text.target.value)}
          mode="onBlur"
        />
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <Controller
              control={control}
              name="state"
              as={
                <TextField
                  label="Estado"
                  color="primary"
                  variant="outlined"
                  inputProps={{ maxLength: 2 }}
                  error={!!errors.state}
                  helperText={errors?.state?.message}
                  fullWidth
                />
              }
              onChange={([e]) => e.target.value.toUpperCase()}
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={6} sm={9}>
            <Controller
              control={control}
              name="city"
              as={
                <TextField
                  label="Cidade"
                  color="primary"
                  variant="outlined"
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  fullWidth
                />
              }
              mode="onBlur"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Controller
          control={control}
          name="neighborhood"
          as={
            <TextField
              label="Bairro"
              color="primary"
              variant="outlined"
              error={!!errors.neighborhood}
              helperText={errors?.neighborhood?.message}
              fullWidth
            />
          }
          mode="onBlur"
        />
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Controller
              control={control}
              name="street"
              as={
                <TextField
                  label="Rua"
                  color="primary"
                  variant="outlined"
                  error={!!errors.street}
                  helperText={errors?.street?.message}
                  fullWidth
                />
              }
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              control={control}
              name="number"
              as={
                <TextField
                  label="Número"
                  color="primary"
                  variant="outlined"
                  error={!!errors.number}
                  helperText={errors?.number?.message}
                  fullWidth
                />
              }
              mode="onBlur"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Controller
          control={control}
          name="complement"
          as={
            <TextField
              label="Complemento"
              color="primary"
              variant="outlined"
              error={!!errors.complement}
              helperText={errors?.complement?.message}
              fullWidth
            />
          }
          mode="onBlur"
        />
      </Box>
      <Box mt={2}>
        <Controller
          control={control}
          name="description"
          as={
            <TextField
              label="Descrição"
              color="primary"
              variant="outlined"
              error={!!errors.description}
              helperText={errors?.description?.message}
              fullWidth
            />
          }
          mode="onBlur"
        />
      </Box>
    </form>
  )
}

export default AddressForm
