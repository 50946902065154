import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  tabWidth: {
    width: 'auto',
    maxWidth: '100%',
  },
}))

export default styles
