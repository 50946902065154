import constants from 'constants/index'

const updateStatus = (status) => {
  switch (status) {
    case constants.order.status.REQUESTED_STATUS:
      return constants.order.status.PENDING_STATUS
    case constants.order.status.PENDING_STATUS:
      return constants.order.status.ACTIVED_STATUS
    case constants.order.status.ACTIVED_STATUS:
      return constants.order.status.CANCELED_STATUS
    default:
      return null
  }
}

export default updateStatus
