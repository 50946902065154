import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import { TextField, Grid, MenuItem, Typography, Box } from '@material-ui/core'
import { TextFieldHidden } from 'components'
import schema from './schema'
import helpers from 'helpers'

const CompanyForm = ({ onSubmit, partner, send, isLoading }) => {
  const { handleSubmit, errors, control, getValues, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: partner?.name ?? '',
      tradeName: partner?.tradeName ?? '',
      description: partner?.description ?? '',
      documentType: 'CNPJ',
      kind: 'C',
      document: helpers.formatters.cnpj(partner?.document) ?? '',
      protocol:
        helpers.partners.splitSiteUrlToProtocol(partner?.siteUrl).protocol ??
        'http://',
      siteUrl:
        helpers.partners.splitSiteUrlToProtocol(partner?.siteUrl).siteUrl ?? '',
    },
  })

  useEffect(() => {
    if (send) {
      handleSubmit(onSubmit(getValues(), reset))()
    }
    // eslint-disable-next-line
  }, [send])

  return (
    <>
      {!isLoading && (
        <form>
          <Controller
            control={control}
            name="documentType"
            as={<TextFieldHidden />}
          />
          <Controller control={control} name="kind" as={<TextFieldHidden />} />
          <Controller
            control={control}
            name="name"
            as={
              <TextField
                label="Razão social"
                color="primary"
                variant="outlined"
                error={!!errors.name}
                helperText={errors?.name?.message}
                fullWidth
              />
            }
            mode="onBlur"
          />
          <Box mt={2}>
            <Controller
              control={control}
              name="tradeName"
              as={
                <TextField
                  label="Nome fantasia"
                  color="primary"
                  variant="outlined"
                  error={!!errors.tradeName}
                  helperText={errors?.tradeName?.message}
                  fullWidth
                />
              }
              mode="onBlur"
            />
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="document"
              as={
                <TextField
                  label="CNPJ"
                  color="primary"
                  variant="outlined"
                  error={!!errors.document}
                  helperText={errors?.document?.message}
                  fullWidth
                />
              }
              onChange={([e]) => {
                return helpers.formatters.cnpj(e.target.value)
              }}
              mode="onBlur"
            />
          </Box>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={2}>
                <Controller
                  control={control}
                  name="protocol"
                  as={
                    <TextField
                      label=""
                      select
                      color="primary"
                      variant="outlined"
                      error={!!errors.protocol}
                      helperText={errors?.protocol?.message}
                      fullWidth
                    >
                      <MenuItem value="http://">
                        <Typography>http://</Typography>
                      </MenuItem>
                      <MenuItem value="https://">
                        <Typography>https://</Typography>
                      </MenuItem>
                    </TextField>
                  }
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} sm={9} md={10}>
                <Controller
                  control={control}
                  name="siteUrl"
                  as={
                    <TextField
                      label="Site"
                      color="primary"
                      variant="outlined"
                      error={!!errors.siteUrl}
                      helperText={errors?.siteUrl?.message}
                      fullWidth
                    />
                  }
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="description"
              as={
                <TextField
                  label="Sobre"
                  color="primary"
                  variant="outlined"
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  fullWidth
                  multiline
                  minRows={6}
                />
              }
              mode="onBlur"
            />
          </Box>
        </form>
      )}
    </>
  )
}

export default CompanyForm
