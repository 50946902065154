import { RadioGroup, Radio, Box, Typography } from '@material-ui/core'

import useStyles from './styles'

const RadioKindPartner = ({ companyType, setCompanyType }) => {
  const classes = useStyles()

  const handleChangeCompanyType = ({ target: { value } }) =>
    setCompanyType(value)

  return (
    <RadioGroup
      defaultValue="P"
      name="companyType"
      onChange={handleChangeCompanyType}
    >
      <Box
        component="label"
        htmlFor="person-company-radio"
        className={classes.radioBox}
        boxShadow={companyType === 'P' ? 6 : 0}
      >
        <Radio id="person-company-radio" color="primary" value="P" />{' '}
        <Typography>Eu sou Pessoa Física</Typography>
      </Box>
      <Box
        component="label"
        htmlFor="company-company-radio"
        className={classes.radioBox}
        marginY={3}
        boxShadow={companyType === 'C' ? 6 : 0}
      >
        <Radio id="company-company-radio" color="primary" value="C" />{' '}
        <Typography>Eu sou Pessoa Jurídica</Typography>
      </Box>
    </RadioGroup>
  )
}

export default RadioKindPartner
