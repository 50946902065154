import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  step: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepConnector: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}))

export default styles
