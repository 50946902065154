import chipStatusColor from './chipStatusColor'
import statusName from './statusName'
import formatPrice from './formatPrice'

const partnerServices = {
  chipStatusColor,
  statusName,
  formatPrice,
}

export default partnerServices
