export const getBaseURL = (project) => envsBaseUrl[project][env]
export const getMarketPlaceToken = () => backofficeCompanyToken[env]

const env = process.env.REACT_APP_API || 'development'

const companyToken = {
  development: '65ebf34ee87c6793c9de9fc3505728fd',
  sandbox: 'b9982de256b4b3b9069589896eb21182',
  production: 'c44f1377d0696dccda201de9848e2b51',
}

const backofficeCompanyToken = {
  development: 'd556bf32c2b53e1798e7a4f6a3b8226b',
  sandbox: 'd556bf32c2b53e1798e7a4f6a3b8226b',
  production: 'd556bf32c2b53e1798e7a4f6a3b8226b',
}

export const COMPANY_TOKEN_LOGIN_WITH_ID = companyToken[env]

const envsBaseUrl = {
  marketPlace: {
    development: 'http://api.partners.localhost:3012',
    sandbox: 'https://api.partners.parceiros.sandbox.dponet.com.br',
    production: 'https://api.partners.parceiros.dponet.com.br',
  },
  marketPlaceBackoffice: {
    development: 'http://api.backoffice.localhost:3012',
    sandbox: 'https://api.backoffice.parceiros.sandbox.dponet.com.br',
    production: 'https://api.backoffice.parceiros.dponet.com.br',
  },
  auth: {
    development: 'https://auth.sandbox.oneid.com.br/auth.js',
    sandbox: 'https://auth.sandbox.oneid.com.br/auth.js',
    production: 'https://auth.oneid.com.br/auth.js',
  },
  oneId: {
    development: 'https://app.sandbox.oneid.com.br',
    sandbox: 'https://app.sandbox.oneid.com.br',
    production: 'https://app.oneid.com.br',
  },
}
