import { Grid, TableContainer, Box, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'

import helpers from 'helpers'
import { getBaseURL } from 'service/env'

const TableDocuments = ({
  documents,
  partnerServiceId,
  showRemove = true,
  handleRemoveDocument = () => {},
}) => {
  return (
    <>
      <TableContainer>
        <Table size="small" emptyMessage="Nenhum documento anexado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell>Documentos</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow key={index}>
                <TableCell>
                  {document?.name ||
                    helpers.formatters.documentName(document.url)}
                </TableCell>
                <TableCell align="right" disableTooltip>
                  <Grid container item justifyContent="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <MenuButton>
                        {partnerServiceId && !document?.name && (
                          <Button
                            fullWidth
                            onClick={() =>
                              window.open(
                                `${getBaseURL('marketPlace')}${document.url}`,
                                '_blank',
                              )
                            }
                          >
                            Visualizar
                          </Button>
                        )}
                        {showRemove && (
                          <Button
                            fullWidth
                            onClick={() =>
                              handleRemoveDocument(document, index)
                            }
                          >
                            Remover
                          </Button>
                        )}
                      </MenuButton>
                    </Box>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TableDocuments
