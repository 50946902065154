import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ ...params }) => {
  return await marketPlaceAPI.get('/company_services', { params })
}

const companyService = {
  get,
}

export default companyService
