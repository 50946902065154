import { Grid, Box } from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab'

import { useOnboard } from 'hooks'

import { ProfileData, Address, Contact, UseTerms } from './components'

const Steps = () => {
  const { activeStep, partner, isPartnerReproved } = useOnboard()

  const showActiveStep = () => {
    switch (activeStep) {
      case 0:
        return <ProfileData />
      case 1:
        return <Address />
      case 2:
        return <Contact />
      case 3:
        return isPartnerReproved() ? <></> : <UseTerms />
      default:
        break
    }
  }

  const partnerObservation = (observation) => {
    // eslint-disable-next-line
    return observation?.replace(`{\"observations\"=>\"`, '').replace('"}', '')
  }

  return (
    <Grid container>
      {partner?.partnerStatusInteractions && isPartnerReproved() && (
        <Grid item xs={12}>
          <Box mb={2}>
            <Alert severity="error">
              <AlertTitle>Motivo da reprova</AlertTitle>
              {partnerObservation(
                partner?.partnerStatusInteractions[
                  partner?.partnerStatusInteractions.length - 1
                ].observations,
              )}
            </Alert>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        {showActiveStep()}
      </Grid>
    </Grid>
  )
}

export default Steps
