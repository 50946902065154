const convertStringToCep = (cep) => {
  if (cep === null || cep === undefined) return ''

  cep = cep.replace(/\D/g, '')

  let l = cep.length

  l = cep.length

  if (l <= 5) {
    return cep
  }
  if (l >= 9) {
    return cep.slice(0, 9).replace(/^(\d{5})(\d{3}).*/, '$1-$2')
  }

  if (l === 6) {
    return cep.replace(/^(\d{5})(\d{1}).*/, '$1-$2')
  }

  return cep.replace(/^(\d{5})(\d{3}).*/, '$1-$2')
}

export default convertStringToCep
