import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import useSnackbar from 'hooks/useSnackbar'

import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import {
  MenuButton,
  BaseActionDialog as DeleteAddressDialog,
  BaseActionDialog as ChangeToMainDialog,
  LoadingBox,
} from 'components'

import {
  DetailsAddressDialog,
  NewAddressDialog,
  UpdateAddressDialog,
} from './components'

import helpers from 'helpers'
import * as service from 'service'

const TableRowAddress = ({
  address,
  handleDetails = () => {},
  handleOpenDelete = () => {},
  handleUpdate = () => {},
  handleMakePrimary = () => {},
  ...rest
}) => {
  return (
    <TableRow size="large" {...rest}>
      <TableCell overFlowSize="92px">{address.id}</TableCell>
      <TableCell overFlowSize="276px">
        {address.city}/{address.state}
      </TableCell>
      <TableCell overFlowSize="276px">
        {address.main ? 'Endereço primário' : 'Endereço secundário'}
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            fullWidth
            onClick={() => {
              handleDetails(address)
            }}
          >
            Detalhes
          </Button>
          <Button fullWidth onClick={() => handleUpdate(address)}>
            Editar
          </Button>
          {!address.main && (
            <>
              <Button fullWidth onClick={() => handleOpenDelete(address)}>
                Excluir
              </Button>
              <Button fullWidth onClick={() => handleMakePrimary(address)}>
                Tornar primário
              </Button>
            </>
          )}
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const AddressesTable = ({
  addresses,
  fullScreen = false,
  onAction = () => {},
}) => {
  const snackbar = useSnackbar()
  const [currentAddress, setCurrentAddress] = useState(null)
  const [detailsAddressDialog, setDetailsAddressDialog] = useState(false)
  const [newAddressDialog, setNewAddressDialog] = useState(false)
  const [updateAddressDialog, setUpdateAddressDialog] = useState(false)
  const [deleteAddressDialog, setDeleteAddressDialog] = useState(false)
  const [changeToMainDialog, setChangeToMainDialog] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleDetails = (address) => {
    setCurrentAddress(address)
    setDetailsAddressDialog(true)
  }

  const handleUpdate = (address) => {
    setCurrentAddress(address)
    setUpdateAddressDialog(true)
  }

  const handleOpenDelete = (address) => {
    setCurrentAddress(address)
    setDeleteAddressDialog(true)
  }

  const handleMakePrimary = (address) => {
    setCurrentAddress(address)
    setChangeToMainDialog(true)
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await service.marketPlace.address.destroy(currentAddress?.id)
      snackbar.open({
        message: 'Endereço excluído com sucesso',
        variant: 'success',
      })
      setDeleteAddressDialog(false)
      onAction()
      setLoading(false)
    } catch (error) {
      handleError(error)
    }
  }

  const handleChangeToMain = async () => {
    setLoading(true)

    try {
      await service.marketPlace.address.update(currentAddress?.id, {
        main: true,
      })
      setChangeToMainDialog(false)
      onAction()
      setLoading(false)
    } catch (error) {
      handleError(error)
    }
  }

  const handleError = (error) => {
    setLoading(false)
    snackbar.open({
      message: helpers.formatters.errorMessage(error),
      variant: 'error',
    })
  }

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <Box width="100%" p={2}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Endereços</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" width="100%" justifyContent="flex-end">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setNewAddressDialog(true)
                    }}
                  >
                    Novo endereço
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box width="100%">
            <PerfectScrollbar
              options={{ wheelPropagation: false, useBothWheelAxes: true }}
            >
              <Table
                size="small"
                emptyMessage="Nenhum endereço encontrado"
                noWrap
              >
                <TableHead>
                  <TableRow height="55px">
                    <TableCell width="15%">Identificador</TableCell>
                    <TableCell width="40%">Cidade/Estado</TableCell>
                    <TableCell width="30%">Tipo de endereço</TableCell>
                    <TableCell width="15%" align="right">
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addresses?.map((address) => (
                    <TableRowAddress
                      address={address}
                      key={address.id}
                      handleDetails={handleDetails}
                      handleUpdate={handleUpdate}
                      handleOpenDelete={handleOpenDelete}
                      handleMakePrimary={handleMakePrimary}
                      hover
                    />
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Box>
        </>
      )}
      <DetailsAddressDialog
        address={currentAddress}
        open={detailsAddressDialog}
        setOpen={setDetailsAddressDialog}
        fullScreen={fullScreen}
      />
      <NewAddressDialog
        open={newAddressDialog}
        setOpen={setNewAddressDialog}
        onAction={onAction}
        fullScreen={fullScreen}
      />
      <UpdateAddressDialog
        open={updateAddressDialog}
        setOpen={setUpdateAddressDialog}
        address={currentAddress}
        onAction={onAction}
        fullScreen={fullScreen}
      />
      <DeleteAddressDialog
        open={deleteAddressDialog}
        setOpen={setDeleteAddressDialog}
        title="Excluir endereço"
        isLoading={isLoading}
        infoMessage="Deseja realmente excluir este endereço? Esta ação é irreversível."
        actionButtonText="Excluir"
        maxWidth="sm"
        action={handleDelete}
        fullScreen={fullScreen}
      />
      <ChangeToMainDialog
        open={changeToMainDialog}
        setOpen={setChangeToMainDialog}
        title="Alterar endereço para primário"
        isLoading={isLoading}
        infoMessage="Deseja realmente definir este endereço como primário?"
        actionButtonText="Definir como primário"
        maxWidth="sm"
        action={handleChangeToMain}
        fullScreen={fullScreen}
      />
    </>
  )
}

export default AddressesTable
