import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Divider,
} from '@material-ui/core'

import useStyles from './styles'

const ConfirmationDialog = ({
  open,
  setOpen,
  title,
  message,
  buttonText,
  ...rest
}) => {
  const classes = useStyles()
  const handleClose = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h5">{title}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1">{message}</Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box className={classes.buttonsBox}>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button color="primary" autoFocus {...rest}>
            {buttonText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
