import useFetch from 'hooks/useFetch'

import { Box, Card, Divider, Typography } from '@material-ui/core'
import { Container, ContentHeader, LoadingBox, Page } from 'components'
import { PartnerServicesForm } from './components'

import * as service from 'service'

const PartnerServicesEdit = ({ match }) => {
  const { serviceId } = match.params

  const { response, isLoading } = useFetch(
    service.marketPlace.partnerService.get,
    {
      partnerServiceId: serviceId,
    },
  )

  return (
    <Page title="Alterar serviço">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Serviços"
          title="Alterar"
          subtitle="Alterar serviço"
        />
        <Card>
          <Box p={2}>
            <Typography variant="h5">Alterar serviço</Typography>
          </Box>
          <Divider />
          <Box mt={3}>
            {isLoading ? (
              <LoadingBox />
            ) : (
              <PartnerServicesForm
                partnerServiceId={serviceId}
                partnerService={response?.data?.partnerService}
              />
            )}
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default PartnerServicesEdit
