import { useState, useEffect } from 'react'

import AuthContext from 'contexts/AuthContext'

import {
  Home as HomeIcon,
  FileText as ServiceIcon,
  BookOpen as BookOpenIcon,
} from 'react-feather'

import constants from 'constants/index'
import * as service from 'service'

const AuthProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [menuItems, setMenuItems] = useState([])
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    loadDataWithAnimation()

    // eslint-disable-next-line
  }, [])

  const loadData = async () => {
    const [responseMe, responsePermissions] = await Promise.all([
      service.marketPlace.user.me(),
      service.marketPlace.user.permissions(),
    ])

    setUser(responseMe?.data?.user)
    setPermissions(responsePermissions?.data?.profilePermissions)

    const menuItems = [
      {
        subheader: 'DPOnet&Você',
        items: [
          {
            title: 'Inicio',
            icon: HomeIcon,
            href: '/home',
          },
          {
            title: 'Minhas informações',
            icon: BookOpenIcon,
            href: '/my-information',
          },
        ],
      },
      {
        subheader: 'Serviços',
        somePermissions: [constants.permissions.SERVICES.LIST],
        items: [
          {
            title: 'Todos',
            icon: ServiceIcon,
            href: '/services',
            permissions: constants.permissions.SERVICES.LIST,
          },
        ],
      },
      {
        subheader: 'Solicitações',
        somePermissions: [constants.permissions.SERVICES.LIST],
        items: [
          {
            title: 'Todas',
            icon: ServiceIcon,
            href: '/orders',
            permissions: constants.permissions.SERVICES.LIST,
          },
        ],
      },
    ]

    setMenuItems(menuItems)
  }

  const loadUser = async () => {
    let response = await service.marketPlace.user.me()
    setUser(response?.data?.user)
    return response?.data?.user
  }

  const loadDataWithAnimation = async () => {
    setIsLoading(true)
    setLoaded(false)

    try {
      await loadData()
    } finally {
      setLoaded(true)
      setIsLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        setUser,
        loaded,
        isLoading,
        permissions,
        menuItems,
        loadDataWithAnimation,
        loadData,
        loadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
