import { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

import { Grid, Avatar, RootRef, Box, Typography } from '@material-ui/core'
import { Upload as UploadIcon } from 'react-feather'

import useStyles from './styles'

import { getBaseURL } from 'service/env'
import * as service from 'service'

const LogoInput = ({ logo, send, isLoading }) => {
  const classes = useStyles()
  const [logoFile, setLogoFile] = useState(null)
  const [image, setImage] = useState(
    logo ? getBaseURL('marketPlace') + logo : '',
  )

  const onDrop = useCallback((acceptedFile) => {
    setLogoFile(acceptedFile[0])
    setImage(URL.createObjectURL(acceptedFile[0]))
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()

  const handleSubmit = async () => {
    if (logoFile) {
      await service.marketPlace.partner.addLogo(logoFile)
    }
  }

  useEffect(() => {
    if (send) {
      handleSubmit()
    }
    //eslint-disable-next-line
  }, [send])

  return (
    <>
      {!isLoading && (
        <Box mb={3}>
          <Grid container>
            <Grid item md={2} xs={12} className={classes.logoContainer}>
              <Avatar src={image} className={classes.logoBox} />
            </Grid>
            <Grid item md={10} xs={12}>
              <RootRef rootRef={ref}>
                <Box
                  {...rootProps}
                  className={classes.inputImageContainer}
                  mb={3}
                >
                  <input {...getInputProps()} />
                  <Box>
                    <UploadIcon />
                  </Box>
                  <Box>
                    <Typography>
                      Arraste e solte ou selecione uma logo
                    </Typography>
                  </Box>
                </Box>
              </RootRef>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default LogoInput
