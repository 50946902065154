import { useState } from 'react'
import { useHistory } from 'react-router'

import useSnackbar from 'hooks/useSnackbar'

import { Box, Button, Divider } from '@material-ui/core'
import { LoadingBox, SuccessButton } from 'components'
import {
  RadioKindPartner,
  LogoInput,
  PersonalForm,
  CompanyForm,
} from './components'

import * as service from 'service'
import helpers from 'helpers'
import { routes } from 'Routes'

const MyInformationForm = ({ partner, onAction = () => {} }) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const [send, setSend] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async (data, resetForm = () => {}) => {
    setLoading(true)
    const params = handleParams(data)

    try {
      await service.marketPlace.partner.update(params)

      snackbar.open({
        message: 'Dados cadastrais atualizados com sucesso',
        variant: 'success',
      })
      history.push(routes.myInformation.show)
    } catch (error) {
      setSend(false)
      setLoading(false)
      resetForm(data)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const handleParams = (data) => {
    const siteUrl = data.siteUrl.length
      ? data.protocol +
        data.siteUrl.replaceAll('http://', '').replaceAll('https://', '')
      : ''

    const params = {
      ...data,
      siteUrl,
      protocol: undefined,
    }

    return params
  }

  const handleReturn = () => {
    history.goBack()
  }

  return (
    <>
      {isLoading && <LoadingBox />}
      <>
        <Box px={5} pb={3}>
          <RadioKindPartner companyType={partner?.kind} isLoading={isLoading} />
          <LogoInput
            logo={partner?.logo?.url}
            send={send}
            isLoading={isLoading}
          />
          {partner?.kind === 'P' ? (
            <PersonalForm
              onSubmit={handleSubmit}
              send={send}
              partner={partner}
              isLoading={isLoading}
            />
          ) : (
            <CompanyForm
              onSubmit={handleSubmit}
              send={send}
              partner={partner}
              isLoading={isLoading}
            />
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Box pr={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleReturn()}
            >
              Cancelar
            </Button>
          </Box>
          <SuccessButton variant="contained" onClick={() => setSend(true)}>
            Salvar
          </SuccessButton>
        </Box>
      </>
    </>
  )
}

export default MyInformationForm
