import { useHistory } from 'react-router-dom'

import { reverse } from 'named-urls'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, Button, Chip, Divider } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'

import { routes } from 'Routes'
import helpers from 'helpers'
import useStyles from './styles'
import { Typography } from '@material-ui/core'

const TableRowPartnerService = ({ partnerService, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleDetails = (partnerServiceId) => {
    history.push(
      reverse(routes.services.show, {
        serviceId: partnerServiceId,
      }),
    )
  }

  return (
    <TableRow size="large" {...rest}>
      <TableCell overFlowSize="92px">{partnerService.id}</TableCell>
      <TableCell overFlowSize="276px">
        {partnerService.companyService.name}
      </TableCell>
      <TableCell overFlowSize="276px">{partnerService.description}</TableCell>
      <TableCell disableTooltip>
        <Chip
          size="small"
          className={helpers.partnerServices.chipStatusColor(
            partnerService.status,
            classes,
          )}
          label={helpers.partnerServices.statusName(partnerService.status)}
        />
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            fullWidth
            size="small"
            onClick={() => {
              handleDetails(partnerService.id)
            }}
          >
            Detalhes
          </Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const PartnerServicesTable = ({ partnerServices }) => {
  return (
    <>
      <Box width="100%" p={2}>
        <Typography variant="h5">Serviços</Typography>
      </Box>
      <Divider />
      <Box width="100%">
        <PerfectScrollbar
          options={{ wheelPropagation: false, useBothWheelAxes: true }}
        >
          <Table size="small" emptyMessage="Nenhum serviço encontrado" noWrap>
            <TableHead>
              <TableRow height="55px">
                <TableCell width="10%">Identificador</TableCell>
                <TableCell width="30%">Nome</TableCell>
                <TableCell width="30%">Descrição</TableCell>
                <TableCell width="20%">Status</TableCell>
                <TableCell width="10%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partnerServices?.map((partnerService) => (
                <TableRowPartnerService
                  partnerService={partnerService}
                  key={partnerService.id}
                  hover
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

export default PartnerServicesTable
