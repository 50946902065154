const errorMessage = (error) => {
  if (error?.response?.data?.error) {
    let message = error.response.data.error.message

    if (error.response.data.error.errors) {
      error.response.data.error.errors.map((er) => {
        return (message = message + ` ${er}`)
      })
    }

    return message
  } else {
    return 'Ocorreu um erro! Por favor, tente mais tarde.'
  }
}

export default errorMessage
