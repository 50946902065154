import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
  Divider,
  withWidth,
} from '@material-ui/core'

import useStyles from './styles'
import { ContactForm } from './components'
import { SuccessButton } from 'components'
import { useOnboard, useSnackbar } from 'hooks'
import * as service from 'service'

const ContactDialog = ({ open, setOpen, width, contact }) => {
  const classes = useStyles()
  const { getContacts } = useOnboard()
  const snackbar = useSnackbar()

  const onSubmit = async (data) => {
    try {
      let message = ''

      if (contact) {
        await service.marketPlace.contact.update(contact.id, data)
        message = 'Contato editado com sucesso!'
      } else {
        await service.marketPlace.contact.create(data)
        message = 'Contato criado com sucesso!'
      }

      await getContacts()

      snackbar.open({
        message: message,
        variant: 'success',
      })
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ??
          'Falha ao salvar contato! Tente novamente!',
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      fullScreen={width === 'xs'}
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box>
          <Typography variant="h5">Contato</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <ContactForm onSubmit={onSubmit} contact={contact} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box className={classes.buttonsBox}>
          <Box pr={2}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Voltar
            </Button>
          </Box>
          <SuccessButton
            type="submit"
            variant="contained"
            color="primary"
            form="contact-form"
          >
            {contact ? 'Editar' : 'Adicionar'}
          </SuccessButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default withWidth()(ContactDialog)
