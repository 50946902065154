import { makeStyles, colors } from '@material-ui/core'

const styles = (backgroundImage) => {
  return makeStyles((theme) => ({
    root: {
      height: '100%',
    },
    logoRoot: {
      background: backgroundImage || theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoBox: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '12vh',
      borderRadius: '50%',
      background: theme.palette.white,
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
    linkContainer: {
      display: 'flex',
    },
    icon: {
      marginLeft: '5px',
      paddingBottom: '5px',
    },
    logoComponent: {
      marginTop: '4px',
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    chipActive: {
      backgroundColor: colors.green[100],
      borderRadius: '4px',
      color: colors.green[800],
      fontWeight: 700,
      height: '21px',
    },
    chipInactive: {
      backgroundColor: colors.blue[100],
      borderRadius: '4px',
      color: colors.blue[800],
      fontWeight: 700,
      height: '21px',
    },
    chipOnBoard: {
      backgroundColor: colors.orange[100],
      borderRadius: '4px',
      color: colors.orange[800],
      fontWeight: 700,
      height: '21px',
    },
    chipReproved: {
      backgroundColor: colors.red[100],
      borderRadius: '4px',
      color: colors.red[800],
      fontWeight: 700,
      height: '21px',
    },
    chipWaitingApproval: {
      backgroundColor: colors.cyan[100],
      borderRadius: '4px',
      color: colors.cyan[800],
      fontWeight: 700,
      height: '21px',
    },
    chipDisabled: {
      backgroundColor: colors.grey[100],
      borderRadius: '4px',
      color: colors.grey[800],
      fontWeight: 700,
      height: '21px',
    },
  }))
}
export default styles
