import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Chip } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { LoadingBox } from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'

import { ContactsDialog } from '../'

const TableRowOrders = ({
  order,
  setLoading,
  onEvent,
  currentTab,
  ...rest
}) => {
  const [openContactDialog, setOpenContactDialog] = useState(false)

  const classes = useStyles()

  const handleDialog = () => setOpenContactDialog(true)

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{order.id}</TableCell>
        <TableCell>{order?.partnerService?.companyService?.name}</TableCell>
        <TableCell>{order?.contractor?.name || 'Não informado'}</TableCell>
        <TableCell>{helpers.formatters.date(order?.createdAt)}</TableCell>
        {currentTab !== constants.order.status.CANCELED_STATUS &&
          currentTab !== constants.order.status.ALL_STATUS && (
            <TableCell>
              <Button onClick={handleDialog}>Contato</Button>
            </TableCell>
          )}
        {currentTab === constants.order.status.ALL_STATUS && (
          <TableCell>
            <Chip
              size="small"
              label={helpers.orders.statusLabel(order?.status)}
              className={helpers.orders.chipStatusColor(order?.status, classes)}
            />
          </TableCell>
        )}
      </TableRow>
      <ContactsDialog
        contact={order?.contractor?.contacts[0]}
        open={openContactDialog}
        setOpen={setOpenContactDialog}
        orderId={order?.id}
        currentTab={currentTab}
        onEvent={onEvent}
        orderStatus={order?.status}
      />
    </>
  )
}

TableRowOrders.propTypes = {
  order: PropTypes.object,
  setLoading: PropTypes.func,
  onEvent: PropTypes.func,
  currentTab: PropTypes.string,
}

const OrdersTable = ({
  orders,
  isLoading,
  currentTab,
  onEvent,
  setLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhuma solicitação encontrada"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow height={55}>
                  <TableCell width="15%">Identificador</TableCell>
                  <TableCell width="20%">Serviço</TableCell>
                  <TableCell width="30%">Solicitante</TableCell>
                  <TableCell width="10%">Data solicitação</TableCell>
                  {currentTab !== constants.order.status.CANCELED_STATUS &&
                    currentTab !== constants.order.status.ALL_STATUS && (
                      <TableCell width="15%">Contato</TableCell>
                    )}
                  {currentTab === constants.order.status.ALL_STATUS && (
                    <TableCell width="15%">Status</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.map((order) => (
                  <TableRowOrders
                    order={order}
                    key={order.id}
                    currentTab={currentTab}
                    setLoading={setLoading}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

OrdersTable.propTypes = {
  orders: PropTypes.array,
  isLoading: PropTypes.bool,
  currentTab: PropTypes.string,
  setLoading: PropTypes.func,
  onEvent: PropTypes.func,
}

export default OrdersTable
