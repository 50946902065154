import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const list = async (params) => {
  const response = await marketPlaceAPI.get('/contacts', { params })

  return response
}

const create = async (params) => {
  return await marketPlaceAPI.post('/contacts', params)
}

const update = async (id, params) => {
  return await marketPlaceAPI.put(`/contacts/${id}`, params)
}

const destroy = async (id) => {
  return await marketPlaceAPI.delete(`/contacts/${id}`)
}

const contact = {
  list,
  create,
  update,
  destroy,
}

export default contact
