import { useState, useEffect } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Table, TableRow, TableCell, TableBody } from 'components/Table'
import { MenuButton, ConfirmationDialog } from 'components'
import { useOnboard, useSnackbar } from 'hooks'
import { ContactDialog } from './components'
import useStyles from './styles'
import * as service from 'service'
import helpers from 'helpers'

const Contact = () => {
  const [openDialogContact, setOpenDialogContact] = useState(false)
  const [openDialogDeleteContact, setOpenDialogDeleteContact] = useState(false)
  const [openDialogMainContact, setOpenDialogMainContact] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)

  const classes = useStyles()
  const { contacts, getContacts, setToNextStep, isPartnerReproved } =
    useOnboard()
  const snackbar = useSnackbar()

  const openDialog = (contacts, setState) => {
    setSelectedContact(contacts)
    setState(true)
  }

  const handleDelete = async () => {
    try {
      await service.marketPlace.contact.destroy(selectedContact.id)
      await getContacts()
      snackbar.open({
        message: 'Contato deletado com sucesso!',
        variant: 'success',
      })
      setOpenDialogDeleteContact(false)
    } catch (error) {
      snackbar.open({
        message: error?.response?.data?.error?.message,
        variant: 'error',
      })
    }
  }

  useState(() => {
    getContacts()
  }, [])

  useEffect(() => {
    if (contacts.length !== 0) {
      setToNextStep(true)
    } else {
      setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [contacts.length])

  const handleChangeMain = async () => {
    try {
      await service.marketPlace.contact.update(selectedContact.id, {
        main: true,
      })
      await getContacts()
      snackbar.open({
        message: 'Contato principal atualizado!',
        variant: 'success',
      })
      setOpenDialogMainContact(false)
    } catch (error) {
      snackbar.open({
        message: error?.response?.data?.error?.message,
        variant: 'error',
      })
    }
  }

  return (
    <Box>
      {isPartnerReproved() && (
        <Box mb={2}>
          <Alert severity="warning">
            <AlertTitle>Atenção</AlertTitle>
            Antes de enviar seu cadastro para análise novamente, se certifique
            de que corrigiu todos os pontos apontados por nossos analistas no
            motivo da reprova.
          </Alert>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="flex-end"
        className={classes.buttonsBox}
      >
        <Button
          startIcon={<PlusCircleIcon size="16" />}
          size="medium"
          variant="outlined"
          onClick={() => openDialog(null, setOpenDialogContact)}
        >
          Adicionar
        </Button>
      </Box>
      <PerfectScrollbar options={{ suppressScrollX: false }}>
        <Table emptyMessage="Nenhum contato encontrado" size="small">
          <TableBody>
            {contacts.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell>
                  <Typography>{contact.email}</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>
                    {helpers.formatters.phone(contact.phone)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>
                    {contact.main ? 'Contato primário' : 'Contato secundário'}
                  </Typography>
                </TableCell>
                <TableCell align="right" disableTooltip>
                  <MenuButton>
                    <Button
                      fullWidth
                      onClick={() => openDialog(contact, setOpenDialogContact)}
                    >
                      Editar
                    </Button>
                    {!contact.main && (
                      <>
                        <Button
                          fullWidth
                          onClick={() =>
                            openDialog(contact, setOpenDialogDeleteContact)
                          }
                        >
                          Excluir
                        </Button>
                        <Button
                          fullWidth
                          onClick={() =>
                            openDialog(contact, setOpenDialogMainContact)
                          }
                        >
                          Tornar primário
                        </Button>
                      </>
                    )}
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>

      <ContactDialog
        open={openDialogContact}
        setOpen={setOpenDialogContact}
        contact={selectedContact}
      />
      <ConfirmationDialog
        open={openDialogDeleteContact}
        setOpen={setOpenDialogDeleteContact}
        title="Contato"
        message="Deseja excluir o contato?"
        buttonText="Excluir"
        onClick={handleDelete}
      />
      <ConfirmationDialog
        open={openDialogMainContact}
        setOpen={setOpenDialogMainContact}
        title="Contato"
        message="Deseja tornar este contato o principal?"
        buttonText="Sim"
        onClick={handleChangeMain}
      />
    </Box>
  )
}

export default Contact
