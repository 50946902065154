import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import usePagination from 'hooks/usePagination'
import useSnackbar from 'hooks/useSnackbar'

import { useTheme } from '@material-ui/styles'

import { reverse } from 'named-urls'

import {
  Container,
  ContentHeader,
  LoadingBox,
  LogStatusDialog,
  MenuButton,
  Page,
  Permitted,
  BaseActionDialog as ChangeStatusDialog,
  BaseActionDialog as DeletePartnerServiceDialog,
} from 'components'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  Tabs,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import {
  PartnerServiceDetails,
  ProvidedServicesTable,
  DocumentsTable,
} from './components'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import { routes } from 'Routes'
import useStyles from './styles'

const PartnerServicesShow = ({ match }) => {
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [partnerService, setPartnerService] = useState()
  const [providedServicesResponse, setProvidedServicesResponse] = useState()
  const [isLoading, setLoading] = useState(true)
  const [logDialog, setLogDialog] = useState(false)
  const [changeStatusDialog, setChangeStatusDialog] = useState(false)
  const [currentTab, setCurrentTab] = useState('clients')
  const [deletePartnerServiceDialog, setDeletePartnerServiceDialog] =
    useState(false)
  const [reload, setReload] = useState(0)
  const { serviceId } = match.params

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  useEffect(() => {
    const loadPartnerService = async () => {
      let response = await service.marketPlace.partnerService.get({
        partnerServiceId: serviceId,
      })

      setPartnerService(response?.data?.partnerService)
    }

    const loadProvidedServices = async () => {
      let response = await service.marketPlace.partnerService.providedServices(
        {
          partnerServiceId: serviceId,
          perPage,
          page,
        },
        [page, perPage],
      )
      setProvidedServicesResponse(response?.data)
    }

    loadPartnerService()
      .then(() => {
        loadProvidedServices()
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 200)
      })
  }, [serviceId, reload, page, perPage])

  const handleEdit = () => {
    history.push(
      reverse(routes.services.edit, {
        serviceId,
      }),
    )
  }

  const handleChangeStatus = async () => {
    setLoading(true)

    let data = {
      ...partnerService,
      status:
        constants.partnerService.CHANGE_STATUS_VALUE[partnerService.status],
    }

    try {
      await service.marketPlace.partnerService.update({
        partnerServiceId: partnerService.id,
        partnerService: data,
      })
      snackbar.open({
        message:
          constants.partnerService.CHANGE_STATUS_SUCCESS_MESSAGES[
            partnerService.status
          ],
        variant: 'success',
      })
      setChangeStatusDialog(false)
      setLoading(false)
      setReload(reload + 1)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await service.marketPlace.partnerService.destroy({
        partnerServiceId: partnerService.id,
      })
      snackbar.open({
        message: 'Serviço excluído com sucesso',
        variant: 'success',
      })
      setDeletePartnerServiceDialog(false)
      setLoading(false)
      history.push(routes.services.all)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const showChangeStatus = () => {
    return (
      constants.partnerService.INACTIVE_STATUS === partnerService.status ||
      constants.partnerService.ACTIVE_STATUS === partnerService.status ||
      constants.partnerService.REPROVED_STATUS === partnerService.status
    )
  }

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
    handleChangePage(event, 0)
  }

  return (
    <Page title="Detalhes do serviço">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Serviços"
          title="Detalhes"
          subtitle="Detalhes do serviço"
        />
        <>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <>
              <Card>
                <Box px={2} py={1}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="h5">Informações</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Permitted
                          someTags={[
                            constants.permissions.SERVICES.UPDATE,
                            constants.permissions.SERVICES.DELETE,
                          ]}
                        >
                          <MenuButton>
                            {![
                              constants.partnerService.WAITING_APPROVAL_STATUS,
                              constants.partnerService.DISABLED_STATUS,
                            ].includes(partnerService.status) && (
                              <Permitted
                                tag={constants.permissions.SERVICES.UPDATE}
                              >
                                <Button fullWidth onClick={() => handleEdit()}>
                                  Editar
                                </Button>
                              </Permitted>
                            )}
                            {showChangeStatus() && (
                              <Permitted
                                tag={constants.permissions.SERVICES.UPDATE}
                              >
                                <Button
                                  fullWidth
                                  onClick={() => setChangeStatusDialog(true)}
                                >
                                  {
                                    constants.partnerService
                                      .CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT[
                                      partnerService.status
                                    ]
                                  }
                                </Button>
                              </Permitted>
                            )}
                            <Permitted
                              tag={constants.permissions.SERVICES.DELETE}
                            >
                              <Button
                                fullWidth
                                onClick={() =>
                                  setDeletePartnerServiceDialog(true)
                                }
                              >
                                Excluir
                              </Button>
                            </Permitted>
                            <Button
                              fullWidth
                              onClick={() => setLogDialog(true)}
                            >
                              Histórico de Alterações
                            </Button>
                          </MenuButton>
                        </Permitted>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box mt={1} p={2}>
                  <PartnerServiceDetails partnerService={partnerService} />
                </Box>
              </Card>
              <Box mt={4}></Box>
              <Tabs
                scrollButtons="auto"
                textColor="primary"
                onChange={handleChangeTabs}
                indicatorColor="primary"
                value={currentTab}
                TabIndicatorProps={{ width: 'auto' }}
                variant="standard"
              >
                {constants.partnerService.DETAILS_TABS.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    className={classes.tabWidth}
                  />
                ))}
              </Tabs>
              <Divider />
              <Card>
                <>
                  {currentTab === 'clients' ? (
                    <>
                      <Box display="flex" width="100%">
                        <Grid container>
                          <ProvidedServicesTable
                            providedServices={
                              providedServicesResponse.providedServices
                            }
                          />
                        </Grid>
                      </Box>
                      <Box px={2} display="flex" justifyContent="flex-end">
                        <TablePagination
                          component="div"
                          count={providedServicesResponse?.meta?.totalCount}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          page={page - 1}
                          rowsPerPage={perPage}
                          rowsPerPageOptions={[1, 5, 10, 25, 100]}
                          labelRowsPerPage={isDesktop ? 'Por página' : ''}
                          nextIconButtonProps={{ size: 'small' }}
                          backIconButtonProps={{ size: 'small' }}
                        />
                      </Box>
                    </>
                  ) : (
                    <Box display="flex" width="100%">
                      <Grid container>
                        <DocumentsTable
                          documents={partnerService?.documents}
                          partnerServiceId={partnerService?.id}
                          showRemove={false}
                        />
                      </Grid>
                    </Box>
                  )}
                </>
              </Card>

              <DeletePartnerServiceDialog
                title="Excluir serviço"
                infoMessage={
                  constants.partnerService.DELETE_DIALOG_INFO_MESSAGE
                }
                actionButtonText="Excluir"
                maxWidth="sm"
                open={deletePartnerServiceDialog}
                setOpen={setDeletePartnerServiceDialog}
                action={handleDelete}
              />
              <ChangeStatusDialog
                title={
                  constants.partnerService.CHANGE_STATUS_DIALOG_TITLES[
                    partnerService.status
                  ]
                }
                infoMessage={
                  constants.partnerService.CHANGE_STATUS_DIALOG_INFO_MESSAGES[
                    partnerService.status
                  ]
                }
                actionButtonText={
                  constants.partnerService
                    .CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT[
                    partnerService.status
                  ]
                }
                open={changeStatusDialog}
                maxWidth="sm"
                setOpen={setChangeStatusDialog}
                action={handleChangeStatus}
              />
              <LogStatusDialog
                open={logDialog}
                setOpen={setLogDialog}
                logs={partnerService.partnerServiceStatusInteractions}
              />
            </>
          )}
        </>
      </Container>
    </Page>
  )
}

export default PartnerServicesShow
