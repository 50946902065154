import { colors } from '@material-ui/core'

const styles = (theme) => ({
  logo: {
    width: '75px',
    height: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linkButton: {
    color: colors.grey[500],
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  linkButtonHelp: {
    color: theme.palette.text.linkHelp,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    marginBottom: '15px',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      opacity: '0.8',
    },
  },
  linkText: {
    textTransform: 'capitalize',
    color: colors.grey[500],
  },
  textMargin: {
    marginLeft: '5px',
  },
  helpBox: {
    backgroundColor: colors.grey[200],
  },
  linkTextHelp: {
    fontWeight: 700,
  },
})

export default styles
