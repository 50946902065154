import clsx from 'clsx'

import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  Box,
  Hidden,
  withWidth,
  StepButton,
} from '@material-ui/core'

import { StepIcon } from './components'
import useStyles from './styles'
import { useOnboard } from 'hooks'

const Stepper = ({ steps, width }) => {
  const classes = useStyles()
  const { activeStep, backStep, setToNextStep, setActiveStep, lastActiveStep } =
    useOnboard()

  const orientationStepper = () => {
    switch (width) {
      case 'xs':
        return 'horizontal'
      case 'sm':
        return 'horizontal'
      default:
        return 'vertical'
    }
  }

  const switchActiveStep = (index) => {
    if (index === activeStep) return

    if (index < activeStep) {
      setToNextStep(false)
      backStep()
    }
    setActiveStep(index)
  }

  return (
    <MuiStepper
      activeStep={lastActiveStep}
      orientation={orientationStepper()}
      connector={<StepConnector className={classes.stepConnector} />}
      className={classes.root}
    >
      {steps.map((step, index) => (
        <Step
          className={clsx({
            [classes.step]:
              steps.length !== index + 1 ||
              orientationStepper() === 'horizontal',
          })}
          key={index}
        >
          <StepButton onClick={() => switchActiveStep(index)}>
            <StepLabel StepIconComponent={StepIcon}>
              <Hidden smDown>
                <Box ml={3}>
                  <Typography>{step}</Typography>
                </Box>
              </Hidden>
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </MuiStepper>
  )
}

export default withWidth()(Stepper)
