import { useState } from 'react'

import MyInformationContext from 'contexts/MyInformationContext'

import * as service from 'service'
import constants from 'constants/index'

const MyInformationProvider = ({ children }) => {
  const [loadingProvider, setLoadingProvider] = useState(false)
  const [responseAddresses, setResponseAddresses] = useState(null)
  const [responseContacts, setResponseContacts] = useState(null)
  const [responseServices, setResponseServices] = useState(null)
  const [currentTabResponse, setCurrentTabResponse] = useState(null)
  const [currentTab, setCurrentTab] = useState('ADDRESSES')

  const loadData = async () => {
    setLoadingProvider(true)

    const [responseAddresses, responseContacts, responseServices] =
      await Promise.all([
        service.marketPlace.address.list,
        service.marketPlace.contact.list,
        service.marketPlace.partnerService.get,
      ])

    setResponseAddresses(responseAddresses?.data)
    setResponseContacts(responseContacts?.data)
    setResponseServices(responseServices?.data?.partnerServices)

    setLoadingProvider(false)
  }

  const searchWithPagination = async (perPage, page) => {
    setLoadingProvider(true)

    let response = null

    if (currentTab === constants.partner.ADDRESSES_TAB) {
      response = await searchAddress({ perPage, page })
    }

    if (currentTab === constants.partner.CONTACTS_TAB) {
      response = await searchContacts({ perPage, page })
    }

    if (currentTab === constants.partner.SERVICES_TAB) {
      response = await searchServices({ perPage, page })
    }

    setCurrentTabResponse(response)
    setLoadingProvider(false)
    return response
  }

  const searchAddress = async (params) => {
    let response = await service.marketPlace.address.list({
      ...params,
    })

    setResponseAddresses(response?.data)
    return response?.data
  }

  const searchContacts = async (params) => {
    let response = await service.marketPlace.contact.list({
      ...params,
    })

    setResponseContacts(response?.data)
    return response?.data
  }

  const searchServices = async (params) => {
    let response = await service.marketPlace.partnerService.get({
      ...params,
    })

    setResponseServices(response?.data)
    return response?.data
  }

  return (
    <MyInformationContext.Provider
      value={{
        responseAddresses,
        responseContacts,
        responseServices,
        currentTabResponse,
        loadData,
        searchWithPagination,
        loadingProvider,
        currentTab,
        setCurrentTab,
      }}
    >
      {children}
    </MyInformationContext.Provider>
  )
}

export default MyInformationProvider
