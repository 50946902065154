import { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import {
  KindCompanyForm,
  KindPersonalForm,
  RadioKindPartner,
  LogoInputComponent,
} from './components'
import { useOnboard, useAuth } from 'hooks'
import * as service from 'service'

const ProfileData = () => {
  const { nextStep, setPartnerIsCreated } = useOnboard()
  const { user } = useAuth()

  const [companyType, setCompanyType] = useState(user?.partner?.kind ?? 'P')
  const [requestErrors, setRequestErrors] = useState([])

  const onSubmit = async (data) => {
    try {
      setRequestErrors([])
      const siteUrl = data.siteUrl.length
        ? data.protocol +
          data.siteUrl.replaceAll('http://', '').replaceAll('https://', '')
        : ''

      const params = {
        ...data,
        siteUrl,
        protocol: undefined,
      }

      if (user?.partner) {
        await service.marketPlace.partner.update(params)
      } else {
        await service.marketPlace.partner.create(params)
      }
      setPartnerIsCreated(true)
      nextStep()
    } catch (error) {
      setRequestErrors(
        error?.response?.data?.error?.errors ?? [
          error?.response?.data?.error?.message,
        ],
      )
    }
  }

  return (
    <Box>
      {!user?.partner?.kind && (
        <RadioKindPartner
          companyType={companyType}
          setCompanyType={setCompanyType}
        />
      )}
      <LogoInputComponent logo={user?.partner?.logo?.url} />
      {companyType === 'P' ? (
        <KindPersonalForm onSubmit={onSubmit} />
      ) : (
        <KindCompanyForm onSubmit={onSubmit} />
      )}

      {requestErrors.length > 0 && (
        <Box mt={2}>
          <Alert severity="error">
            <AlertTitle>Ocorreu um erro</AlertTitle>
            {requestErrors.map((error) => (
              <Typography key={error}>{error}</Typography>
            ))}
          </Alert>
        </Box>
      )}
    </Box>
  )
}

export default ProfileData
