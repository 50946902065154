import { Box, Typography, Button, Card } from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab'

import useStyles from '../../styles'

const DisabledCard = ({
  observation,
  handleOpenDesk = () => {},
  goBack = () => {},
}) => {
  const classes = useStyles()
  return (
    <Card>
      <Box m={3} maxWidth="500px" alignContent="justify">
        <Box mb={2}>
          <Typography variant="h4">Usuário desativado</Typography>
        </Box>
        <Typography align="justify">
          Seu cadastro foi desabilitado por um de nossos analistas, o motivo
          desta ação foi:
        </Typography>
        <Box mt={2}>
          <Alert severity="error">
            <AlertTitle>Motivo da desabilitação</AlertTitle>
            <Typography align="justify">{observation}</Typography>
          </Alert>
        </Box>
        <Box mt={2}>
          <Typography>
            Caso possua alguma dúvida entre em contato conosco.
          </Typography>
        </Box>
        <Box mt={2} className={classes.buttonContainer}>
          <Button variant="contained" color="primary" onClick={goBack}>
            Voltar
          </Button>
          <Button onClick={handleOpenDesk} className={classes.buttonSpacing}>
            Entrar em contato
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default DisabledCard
