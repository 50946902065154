import { Box, Grid, Button, Divider } from '@material-ui/core'

import useStyles from './styles'
import { useOnboard, useSnackbar } from 'hooks'
import constants from 'constants/index'
const StepperButton = () => {
  const classes = useStyles()
  const {
    addresses,
    contacts,
    setToNextStep,
    setLastActiveStep,
    lastActiveStep,
    activeStep,
    backStep,
    nextStep,
    finishOnboard,
    acceptedTerms,
    partner,
    isPartnerReproved,
  } = useOnboard()

  const snackbar = useSnackbar()

  const showActiveStepButtonText = () => {
    switch (activeStep) {
      case 0:
        return 'Próximo passo'
      case 1:
        return 'Próximo passo'
      case 2:
        return isPartnerReproved() ? 'Enviar para análise' : 'Próximo passo'
      case 3:
        return 'Finalizar'
      default:
        break
    }
  }

  const switchStepFunction = () => {
    if (
      activeStep === 2 &&
      partner.status === constants.partner.REPROVED_STATUS
    ) {
      if (contacts.length > 0) {
        return finishOnboard()
      } else {
        snackbar.open({
          message:
            'É necessário ao menos um contato cadastrado para prosseguir.',
          variant: 'error',
        })

        setToNextStep(false)
      }
    }

    if (activeStep === 3) {
      if (acceptedTerms) {
        finishOnboard()
      } else {
        snackbar.open({
          message:
            'É necessário que você aceite os termos de uso da plataforma para prosseguir.',
          variant: 'error',
        })

        setToNextStep(false)
      }
    }

    if (activeStep === 2) {
      if (contacts.length > 0) {
        setToNextStep(true)
        nextStep()
      } else {
        snackbar.open({
          message:
            'É necessário ao menos um contato cadastrado para prosseguir.',
          variant: 'error',
        })

        setToNextStep(false)
      }
    } else {
      if (activeStep === 1) {
        if (addresses.length > 0) {
          setToNextStep(true)
          if (lastActiveStep < 2) setLastActiveStep(2)
          nextStep()
        } else {
          snackbar.open({
            message:
              'É necessário ao menos um endereço cadastrado para prosseguir.',
            variant: 'error',
          })
          setToNextStep(false)
        }
      } else {
        if (lastActiveStep < 1) setLastActiveStep(1)
        setToNextStep(true)
      }
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.buttonsBox}
        >
          {activeStep > 0 && (
            <Button
              variant="outlined"
              className={classes.goBackButton}
              onClick={() => {
                setToNextStep(false)
                backStep()
              }}
            >
              Voltar
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => switchStepFunction()}
          >
            {showActiveStepButtonText()}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default StepperButton
