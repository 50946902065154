import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'

const DialogHelpDesk = ({ open, setOpen = () => {} }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
  }, [open])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Atendimento</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {loading && (
          <Box
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
        <Box>
          <iframe
            width="100%"
            height="600px"
            title="helpDeskIFrame"
            onLoad={() => setLoading(false)}
            frameborder="0"
            src="https://dponet.octadesk.com/custom-form/27824fb9-92f8-482b-aea5-1db14d6fcaaf"
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          onClick={() => setOpen(false)}
          component="a"
        >
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogHelpDesk.prototype = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

export default DialogHelpDesk
