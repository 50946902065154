import { TextField } from '@material-ui/core'

import useStyles from './styles'

const TextFieldHidden = (props) => {
  const classes = useStyles()

  return (
    <TextField
      type="hidden"
      InputProps={{
        classes: {
          notchedOutline: classes.inputHidden,
        },
      }}
      {...props}
    />
  )
}

export default TextFieldHidden
