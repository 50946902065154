import { useFetch } from 'hooks'

import { Box, Card, Divider, Typography } from '@material-ui/core'
import { Container, ContentHeader, LoadingBox, Page } from 'components'
import { MyInformationForm } from './components'

import * as service from 'service'

const MyInformationEdit = () => {
  const { response, isLoading, refresh } = useFetch(service.marketPlace.user.me)

  return (
    <Page title="Atualizar os dados cadastrais">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Meu dados"
          title="Atualização"
          subtitle="Atualizar os dados cadastrais"
        />
        <Card>
          <Box p={2}>
            <Typography variant="h5">Atualizar os dados cadastrais</Typography>
          </Box>
          <Divider />
          <Box mt={3}>
            {isLoading ? (
              <LoadingBox />
            ) : (
              <MyInformationForm
                partner={response?.data?.user?.partner}
                onAction={refresh}
              />
            )}
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default MyInformationEdit
