import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import helpers from 'helpers'

const TableRowProvidedService = ({ providedService, ...rest }) => {
  const contractor = providedService?.contractor

  return (
    <TableRow {...rest}>
      <TableCell>{providedService.id}</TableCell>
      <TableCell>{contractor.name}</TableCell>
      <TableCell>
        {helpers.formatters.date(providedService.createdAt)}
      </TableCell>
      <TableCell>
        {helpers.formatters.date(providedService.expirationDate)}
      </TableCell>
    </TableRow>
  )
}

const ProvidedServicesTable = ({ providedServices }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          size="small"
          emptyMessage="Nenhum parceiro relacionado a este serviço foi encontrado"
          noWrap
        >
          <TableHead>
            <TableRow height="55px">
              <TableCell width="20%">Identificador</TableCell>
              <TableCell width="30%">Nome do cliente</TableCell>
              <TableCell width="25%">Data da contratação</TableCell>
              <TableCell width="25%">Data do fim do contrato</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providedServices?.map((providedService) => (
              <TableRowProvidedService
                providedService={providedService}
                hover
                height="50px"
                key={providedService.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

export default ProvidedServicesTable
