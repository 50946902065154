import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
      flexDirection: 'column-reverse',
    },
  },
}))

export default styles
