import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Fab,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { HelpCircle as HelpIcon } from 'react-feather'

const HelpDialog = ({ about, title, helpText }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Box
        display="flex"
        margin="20px"
        position="fixed"
        zIndex="99999"
        bottom="0"
        right="0"
      >
        <Tooltip title="Ajuda">
          <Fab size="small" color="secondary" onClick={() => setOpen(true)}>
            <HelpIcon />
          </Fab>
        </Tooltip>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box>
            <Typography variant="h6">{about}</Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box width="100%" height="100%">
            <Grid container>
              <Grid item xs={12}>
                <Box py={2}>
                  <Typography variant="h4">{title}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box pb={5} width="100%">
                  <Typography variant="body1" align="justify" gutterBottom>
                    {helpText}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => setOpen(false)}
              component="a"
            >
              Fechar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

HelpDialog.prototype = {
  about: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
}

export default HelpDialog
