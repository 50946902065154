import { useEffect, useState } from 'react'

import usePagination from 'hooks/usePagination'
import useMyInformation from 'hooks/useMyInformation'

import { useTheme } from '@material-ui/styles'
import {
  Box,
  Card,
  Divider,
  Tab,
  Tabs,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'
import { LoadingBox } from 'components'
import {
  AddressesTable,
  ContactsTable,
  PartnerServicesTable,
} from './components'

import constants from 'constants/index'

const MyInformationTables = ({ isLoading }) => {
  const theme = useTheme()
  const {
    currentTab,
    setCurrentTab,
    currentTabResponse,
    loadingProvider,
    searchWithPagination,
  } = useMyInformation()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const isMobile = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  })

  const [reload, setReload] = useState(0)
  const handleReload = () => {
    setReload(reload + 1)
  }

  const [currentTable, setCurrentTable] = useState(
    <AddressesTable
      addresses={currentTabResponse?.addresses}
      onAction={handleReload}
      fullScreen={!isMobile}
    />,
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  useEffect(() => {
    const defineCurrentTable = (response) => {
      if (currentTab === constants.partner.SERVICES_TAB) {
        setCurrentTable(
          <PartnerServicesTable partnerServices={response?.partnerServices} />,
        )
      }

      if (currentTab === constants.partner.ADDRESSES_TAB) {
        setCurrentTable(
          <AddressesTable
            addresses={response?.addresses}
            onAction={handleReload}
            fullScreen={!isMobile}
          />,
        )
      }

      if (currentTab === constants.partner.CONTACTS_TAB) {
        setCurrentTable(
          <ContactsTable
            contacts={response?.contacts}
            onAction={handleReload}
            fullScreen={!isMobile}
          />,
        )
      }
    }

    searchWithPagination(perPage, page).then((response) => {
      defineCurrentTable(response)
    })

    //eslint-disable-next-line
  }, [page, perPage, currentTab, reload])

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
    handleChangePage(event, 0)
  }

  return (
    <>
      {!isLoading && (
        <>
          <Tabs
            scrollButtons="auto"
            onChange={handleChangeTabs}
            textColor="primary"
            indicatorColor="primary"
            value={currentTab}
          >
            {constants.partner.MY_INFORMATION_TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          <Divider />
          <Card>
            {loadingProvider ? (
              <LoadingBox />
            ) : (
              <>
                <Box mt={1}>{currentTable}</Box>
                {currentTabResponse && (
                  <Box px={2} display="flex" justifyContent="flex-end">
                    <TablePagination
                      component="div"
                      count={currentTabResponse?.meta?.totalCount}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      page={page - 1}
                      rowsPerPage={perPage}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      labelRowsPerPage={isDesktop ? 'Por página' : ''}
                      nextIconButtonProps={{ size: 'small' }}
                      backIconButtonProps={{ size: 'small' }}
                    />
                  </Box>
                )}
              </>
            )}
          </Card>
        </>
      )}
    </>
  )
}

export default MyInformationTables
