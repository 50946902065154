const ACTIVED_STATUS = 'actived'
const CANCELED_STATUS = 'canceled'
const PENDING_STATUS = 'pending'
const REQUESTED_STATUS = 'requested'
const ALL_STATUS = ''

const TABS = [
  {
    value: ALL_STATUS,
    label: 'Todos',
  },
  {
    value: REQUESTED_STATUS,
    label: 'Solicitados',
  },
  {
    value: PENDING_STATUS,
    label: 'Em andamento',
  },
  {
    value: ACTIVED_STATUS,
    label: 'Ativos',
  },
  {
    value: CANCELED_STATUS,
    label: 'Cancelados',
  },
]

const status = {
  ACTIVED_STATUS,
  CANCELED_STATUS,
  REQUESTED_STATUS,
  PENDING_STATUS,
  ALL_STATUS,
}

const order = {
  status,
  TABS,
}

export default order
