import { RadioGroup, Radio, Box, Typography } from '@material-ui/core'
import { Label } from 'components'

import useStyles from './styles'

const RadioKindPartner = ({ companyType, isLoading }) => {
  const classes = useStyles()

  return (
    <>
      {!isLoading && (
        <Label title="Tipo de cadastro">
          <RadioGroup defaultValue={companyType} name="companyType">
            <Box
              component="label"
              htmlFor="person-company-radio"
              className={classes.radioBox}
              boxShadow={companyType === 'P' ? 6 : 0}
            >
              <Radio
                id="person-company-radio"
                color="primary"
                value="P"
                disabled
              />{' '}
              <Typography>Eu sou Pessoa Física</Typography>
            </Box>
            <Box
              component="label"
              htmlFor="company-company-radio"
              className={classes.radioBox}
              marginY={3}
              boxShadow={companyType === 'C' ? 6 : 0}
            >
              <Radio
                id="company-company-radio"
                color="primary"
                value="C"
                disabled
              />{' '}
              <Typography>Eu sou Pessoa Jurídica</Typography>
            </Box>
          </RadioGroup>
        </Label>
      )}
    </>
  )
}

export default RadioKindPartner
