import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import OnboardContext from 'contexts/OnboardContext'
import { useAuth } from 'hooks'
import * as service from 'service'
import partnerConstants from 'constants/partner'
import { routes } from 'Routes'
import helpers from 'helpers'

const OnboardProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [toNextStep, setToNextStep] = useState(false)
  const [addresses, setAddresses] = useState([])
  const [contacts, setContacts] = useState([])
  const [isLastStep, setIsLastStep] = useState(false)
  const [lastActiveStep, setLastActiveStep] = useState(0)
  const [partnerIsCreated, setPartnerIsCreated] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [partner, setPartner] = useState({})

  const { loadData, user } = useAuth()

  const finishOnboard = async () => {
    var today = new Date()

    await service.marketPlace.partner.update({
      status: partnerConstants.WAITING_APPROVAL_STATUS,
      termsAcceptedAt: helpers.formatters.date(today),
    })

    await loadData()

    service.history.push(routes.home)
  }

  const isPartnerReproved = () => {
    return user?.partner?.status === partnerConstants.REPROVED_STATUS
  }

  const identifyActualOnboardStep = async () => {
    if (!user?.partner) {
      setPartnerIsCreated(false)
      setIsLastStep(false)
      setLastActiveStep(0)
      return setActiveStep(0)
    }

    setPartner(user.partner)
    setPartnerIsCreated(true)

    const addresses = await getAddresses()

    if (addresses?.length === 0) {
      setIsLastStep(false)
      setLastActiveStep(1)
      return setActiveStep(1)
    }

    const contacts = await getContacts()

    if (isPartnerReproved()) {
      setIsLastStep(true)
      setLastActiveStep(2)
      return setActiveStep(2)
    }

    if (contacts?.length === 0) {
      setIsLastStep(false)
      setLastActiveStep(2)
      return setActiveStep(2)
    }

    setIsLastStep(true)
    setLastActiveStep(3)
    setAcceptedTerms(user?.partner?.termsAcceptedAt ? true : false)
    return setActiveStep(3)
  }

  const getAddresses = async () => {
    const response = await service.marketPlace.address.list({})
    setAddresses(response?.data?.addresses)

    return response?.data?.addresses
  }

  const getContacts = async () => {
    const response = await service.marketPlace.contact.list({})
    setContacts(response?.data?.contacts)

    return response?.data?.contacts
  }

  const nextStep = async () => {
    await loadData()

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    const identifyActualStep = async () => {
      await identifyActualOnboardStep()
    }
    identifyActualStep()
    // eslint-disable-next-line
  }, [])

  return (
    <OnboardContext.Provider
      value={{
        acceptedTerms,
        setAcceptedTerms,
        isLastStep,
        lastActiveStep,
        setLastActiveStep,
        setIsLastStep,
        activeStep,
        setActiveStep,
        toNextStep,
        setToNextStep,
        nextStep,
        backStep,
        finishOnboard,
        getAddresses,
        getContacts,
        addresses,
        contacts,
        partner,
        setPartner,
        isPartnerReproved,
        partnerIsCreated,
        setPartnerIsCreated,
      }}
    >
      {children}
    </OnboardContext.Provider>
  )
}

OnboardProvider.propTypes = {
  children: PropTypes.node,
}

export default OnboardProvider
