import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'

import { Label } from 'components'

import helpers from 'helpers'

const DetailsContactDialog = ({
  contact,
  open,
  fullScreen = false,
  setOpen = () => {},
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h5">Detalhes do contato</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <Label title="Identificador">
                <Typography variant="body1" color="secondary">
                  {contact?.id}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Tipo do contato">
                <Typography variant="body1" color="secondary">
                  {contact?.main ? 'Contato primário' : 'Contato secundário'}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Telefone">
                <Typography variant="body1" color="secondary">
                  {helpers.formatters.phone(contact?.phone) || '-'}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Email">
                <Typography variant="body1" color="secondary">
                  {contact?.email || '-'}
                </Typography>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label title="Descrição">
                <Typography variant="body1" color="secondary">
                  {contact?.description || '-'}
                </Typography>
              </Label>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box px={2} display="flex" justifyContent="flex-end" width="100%">
          <Box pl={2} pr={1}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default DetailsContactDialog
