import api from 'service/api'
import axios from 'axios'

import { getBaseURL } from '../../env'
import * as service from 'service'

const marketPlaceAPI = api.create('marketPlace', false)
const marketPlaceAPIWithErrorTreat = api.create('marketPlace', true)
const baseURL = getBaseURL('marketPlace')

const get = async ({ partnerServiceId = '', ...params }) => {
  return await marketPlaceAPIWithErrorTreat.get(
    `/partner_services/${partnerServiceId}`,
    {
      params,
    },
  )
}

const create = async (data) => {
  return await marketPlaceAPI.post('/partner_services', data)
}

const update = async ({ partnerServiceId, ...data }) => {
  return await marketPlaceAPI.put(`/partner_services/${partnerServiceId}`, data)
}

const destroy = async ({ partnerServiceId }) => {
  return await marketPlaceAPI.delete(`/partner_services/${partnerServiceId}`)
}

const providedServices = async ({ partnerServiceId, ...params }) => {
  return await marketPlaceAPI.get(
    `/partner_services/${partnerServiceId}/provided_services`,
    { params },
  )
}

const addDocument = async ({ partnerServiceId, files }) => {
  var formData = new FormData()

  for (var [key, value] of Object.entries(files)) {
    formData.append(`document-${key}`, value)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.marketPlace.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/partner_services/${partnerServiceId}/add_document`,
    data: formData,
    ...config,
  })
}

const deleteDocument = async ({ partnerServiceId, ...data }) => {
  return await marketPlaceAPI.delete(
    `/partner_services/${partnerServiceId}/destroy_document`,
    data,
  )
}

const partnerService = {
  get,
  create,
  update,
  providedServices,
  destroy,
  addDocument,
  deleteDocument,
}

export default partnerService
