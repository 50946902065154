import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  goBackButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
}))

export default styles
