import api from 'service/api'

const marketPlaceAPI = api.create('marketPlaceBackoffice')

const listServiceCategories = async ({ ...params }) => {
  const response = await marketPlaceAPI.get(
    '/showcases/service_categories',
    params,
  )
  return response
}

const showcases = {
  listServiceCategories,
}

export default showcases
