import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  radioBox: {
    display: 'flex',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderRadius: theme.spacing(0.5),
    borderStyle: 'solid',
    padding: theme.spacing(1, 2),
  },
}))

export default styles
