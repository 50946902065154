import { useState, useEffect } from 'react'
import useAuth from 'hooks/useAuth'

import { Container, ContentHeader, LoadingBox, Page } from 'components'
import { Box, Card, Grid } from '@material-ui/core'
import {
  PartnerLogoCard,
  PartnerDetails,
  MyInformationTables,
} from './components'

const MyInformationShow = () => {
  const auth = useAuth()
  const [isLoading, setLoading] = useState(false)
  const [reload, setReload] = useState(0)

  useEffect(() => {
    setLoading(true)

    auth.loadData().finally(() => {
      setLoading(false)
    })

    //eslint-disable-next-line
  }, [reload])

  const partner = auth?.user?.partner

  const refresh = () => {
    setReload(reload + 1)
  }

  return (
    <>
      {isLoading && <LoadingBox />}
      <Page title="Minhas informações">
        <Container
          maxWidth={false}
          spacingXl={30}
          spacingLg={25}
          spacingMd={10}
          spacingSm={10}
        >
          <ContentHeader
            menu="Minhas informações"
            title="Visualização"
            subtitle="Meus dados detalhados"
          />
          <>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Card>
                  <PartnerLogoCard partner={partner} isLoading={isLoading} />
                </Card>
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <Card>
                  <PartnerDetails
                    partner={partner}
                    refresh={refresh}
                    setLoading={setLoading}
                    isLoading={isLoading}
                  />
                </Card>
              </Grid>
            </Grid>
            <Box mt={4}>
              <Grid container>
                <Grid item xs={12}>
                  <MyInformationTables isLoading={isLoading} />
                </Grid>
              </Grid>
            </Box>
          </>
        </Container>
      </Page>
    </>
  )
}

export default MyInformationShow
