import theme from './theme'
import landingPage from './landingPage'
import partner from './partner'
import partnerService from './partnerService'
import permissions from './permissions'
import order from './order'

const constants = {
  theme,
  landingPage,
  partner,
  permissions,
  partnerService,
  order,
}

export default constants
