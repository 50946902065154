import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
    },
  },
  buttonSpacing: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}))

export default styles
