import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
  Divider,
  withWidth,
} from '@material-ui/core'

import useStyles from './styles'
import { AddressForm } from './components'
import { SuccessButton } from 'components'
import { useOnboard, useSnackbar } from 'hooks'
import * as service from 'service'

const AddressDialog = ({ open, setOpen, width, address }) => {
  const classes = useStyles()
  const { getAddresses } = useOnboard()
  const snackbar = useSnackbar()

  const onSubmit = async (data) => {
    try {
      let message = ''

      if (address) {
        await service.marketPlace.address.update(address.id, data)
        message = 'Endereço editado com sucesso!'
      } else {
        await service.marketPlace.address.create(data)
        message = 'Endereço criado com sucesso!'
      }

      await getAddresses()

      snackbar.open({
        message: message,
        variant: 'success',
      })
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ??
          'Falha ao salvar Endereço! Tente novamente!',
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      fullScreen={width === 'xs'}
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box>
          <Typography variant="h5">Endereço</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <AddressForm onSubmit={onSubmit} address={address} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box className={classes.buttonsBox}>
          <Box pr={2}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Voltar
            </Button>
          </Box>
          <SuccessButton
            type="submit"
            variant="contained"
            color="primary"
            form="address-form"
          >
            {address ? 'Editar' : 'Adicionar'}
          </SuccessButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default withWidth()(AddressDialog)
