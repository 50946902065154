import { useEffect } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Label } from 'components'
import { useOnboard } from 'hooks'

const UseTerms = () => {
  const { setToNextStep, acceptedTerms, setAcceptedTerms } = useOnboard()

  useEffect(() => {
    setToNextStep(acceptedTerms)
    // eslint-disable-next-line
  }, [acceptedTerms])

  const handleChange = (event) => {
    setAcceptedTerms(event.target.checked)
  }

  return (
    <Box>
      <Label title="Termos de uso" titleVariant="h4">
        <Box height="400px">
          <PerfectScrollbar>
            <Typography variant="body1" color="secondary" gutterBottom>
              Bem vindo(a)! Obrigado por utilizar o DPOnet Parceiros!
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              Esta aplicação e seu conteúdo (&quot;
              <strong>DPOnet Parceiros</strong>
              &quot;) são controlados e operados pelo <strong>DPOnet</strong>
              (Immunize Desenvolvimento de Sistemas e Consultoria Ltda., CNPJ nº
              36.487.128/0001-79).{' '}
              <strong>Todos os direitos reservados.</strong>
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              Estes termos de uso têm por objeto definir as regras a serem
              seguidas para a utilização do DPOnet Parceiros (&quot;
              <strong>Termos de Uso</strong>&quot;), sem prejuízo da aplicação
              da legislação vigente.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>
                AO UTILIZAR O <em>DPONET PARCEIROS</em>, VOCÊ AUTOMATICAMENTE
                CONCORDA COM ESTES TERMOS DE USO, RESPONSABILIZANDO-SE
                INTEGRALMENTE POR TODOS E QUAISQUER ATOS PRATICADOS POR VOCÊ NO{' '}
                <em>DPONET PARCEIROS</em> OU EM SERVIÇOS A ELE RELACIONADOS.
                CASO VOCÊ NÃO CONCORDE COM QUALQUER DOS TERMOS E CONDIÇÕES
                ABAIXO ESTABELECIDOS, VOCÊ NÃO DEVE UTILIZAR O{' '}
                <em>DPONET PARCEIROS.</em>
              </strong>
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              Caso queira nos dar algum feedback sobre o DPOnet Parceiros, tenha
              dúvidas ou precise tratar de qualquer assunto relacionado a estes
              Termos de Uso, entre em contato conosco através do e-mail{' '}
              <strong>atendimento@dponet.com.br</strong>
            </Typography>
            <Box my={2}>
              <Typography variant="body1" color="secondary" align="justify">
                <strong>1. O QUE É O DPONET PARCEIROS?</strong>
              </Typography>
            </Box>
            <Typography variant="body1" color="secondary" align="justify">
              <strong>1.1. Serviços.</strong> O DPOnet Parceiros é uma
              plataforma que oferece os seguintes serviços: intermediação de
              prestação de serviços e oferecimento de outras soluções
              relacionadas a Lei Geral de Proteção de Dados, podendo ser
              serviços de consultoria, softwares, aplicações (desde que
              complementares e não concorrentes com o serviço do DPOnet), com o
              objetivo de garantir a segurança dos dados pessoais.
            </Typography>
            <Box my={2}>
              <Typography variant="body1" color="secondary" align="justify">
                <strong>
                  2. COMO ACESSO E ME TORNO UM PARCEIRO NO DPONET PARCEIROS?
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>2.1. Acesso.</strong> Para acessar o DPOnet Parceiros e
              utilizar suas funcionalidades é necessário efetuar um cadastro.
              Para cadastrar-se como Parceiro, você nos fornecerá informações
              pessoais, tais como nome e CPF, caso pessoa física, ou razão
              social, nome fantasia e CNPJ, caso pessoa jurídica, além de
              endereço(s), e-mail(s) e número(s) de telefone(s) para contato.
              Para saber mais sobre a privacidade de suas informações pessoais
              no DPOnet Parceiros, acesse nossa Política de Privacidade.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>2.2. Elegibilidade dos Parceiros.</strong> Para utilizar o
              DPOnet Parceiros, você deve ter pelo menos 18 (dezoito) anos, ou
              ser emancipado(a), nos moldes da legislação aplicável. Ao aceitar
              estes Termos de Uso, você declara ser plenamente capaz para
              exercer todos os atos da vida civil. Se o cadastro no DPOnet for
              efetuado em nome de pessoa jurídica, você declara que tem poder e
              todas as autorizações necessárias para vinculá-la regularmente.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>2.3. Verificação.</strong> O DPOnet leva a integridade da
              comunidade que usa nossa plataforma muito a sério, e por isso nos
              reservamos o direito de realizarmos verificações antes ou depois
              do cadastro dos usuários, da oferta de um serviço ou da inserção
              de algum conteúdo em nossa plataforma, bem como o direito de
              exigirmos informações adicionais ou até mesmo alterações a um ou
              mais serviços cadastrados na plataforma. Quaisquer referências a
              um usuário ou produto como sendo “analisado” (ou linguagem
              similar) apenas indica que o usuário ou produto se submete a um
              processo de verificação cadastral, e nada mais. Lembramos que são
              os Parceiros os responsáveis por cumprir todas as regras
              aplicáveis aos serviços por eles cadastrados e às suas respectivas
              comercializações.
            </Typography>
            <Box ml={5} my={1}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>2.3.1.</strong> O DPOnet poderá exigir requisitos
                específicos que demonstrem capacidade, habilidade, e/ou
                autorização para prestar um serviço específico ou oferecer uma
                solução específica. O DPOnet se reserva o direito de aumentar as
                exigências mesmo que o serviço ou solução já tenha sido
                aprovado, não prejudicando contratações já concluídas ou em
                andamento.{' '}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>2.4. Recusa, Suspensão ou Cancelamento.</strong> Nós nos
              reservamos o direito de recusar, suspender ou cancelar, a qualquer
              momento, o seu acesso à aplicação em caso de suspeita de fraude,
              obtenção de benefício ou vantagem de forma ilícita, ou pelo não
              cumprimento de quaisquer condições previstas nestes Termos de Uso
              ou na legislação aplicável. De mesmo modo, se tomarmos
              conhecimento, por nós ou por denúncia fundamentada de terceiro, da
              existência de condenação sua, mediante sentença transitada em
              julgado, por (a) crimes hediondos, ou equiparados a crimes
              hediondos; (b) envolvimento em organizações criminosas, lavagem de
              dinheiro, atos terroristas ou tráfico internacional de pessoas; ou
              (c) crimes cometidos por meios eletrônicos ou mecanismos
              cibernéticos. Nesses casos, não será devida qualquer indenização a
              você, e o DPOnet poderá promover a competente ação de regresso, se
              necessário, bem como tomar quaisquer outras medidas necessárias
              para perseguir e resguardar seus interesses.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>2.5. Titularidade.</strong> A partir da aprovação do
              cadastro, você será titular de uma conta que somente poderá ser
              acessada por você. Caso o DPOnet detecte alguma conta feita a
              partir de informações falsas, por usuários que, por exemplo, não
              possuem a idade mínima permitida, essa conta será automaticamente
              encerrada.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>2.6. Suspensão ou Encerramento da Conta.</strong> Se o
              DPOnet limitar seu acesso e/ou uso da Plataforma, suspender ou
              encerrar sua conta, você não poderá registrar uma nova conta na
              Plataforma, nem acessar ou usar a plataforma através da conta de
              outro usuário, sem autorização prévia do DPOnet e desde que os
              motivos que ensejaram a limitação de acesso e/ou uso, suspensão ou
              encerramento da conta, deixem de subsistir.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>2.7. Atualização das Informações.</strong> Desde já, você
              se compromete a manter as suas informações pessoais atualizadas.
              Você é o único responsável por cadastrar dados verdadeiros, exatos
              e atualizados, e responde pelas consequências dos dados ou
              informações inverídicos, incompletos ou incorretos que fornecer no
              cadastro ou depois dele. Recomendamos que você confira sempre as
              informações fornecidas à plataforma antes de concluir o seu
              cadastro. Você também concorda que irá manter o seu login e senha
              seguros e fora do alcance de terceiros, e não permitirá que a sua
              conta no DPOnet seja usada por outras pessoas. Dessa forma, o
              Parceiro responsabiliza-se por todas as ações realizadas em sua
              conta.
            </Typography>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  3. AS OBRIGAÇÕES DO PARCEIRO PERANTE A DPONET E AS PESSOAS
                  JURÍDICAS E PESSOAS FÍSICAS
                </strong>
              </Typography>
            </Box>

            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>3.1. Obrigações do Parceiro.</strong> Como parceiro, você
              é responsável pelos serviços prestados às pessoas jurídicas e
              físicas, que contratarão seus serviços e soluções na plataforma.
              Você declara e garante que:
            </Typography>
            <Box ml={5} mt={2}>
              <Box my={1}>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  a) fornecerá e manterá as informações da conta precisas;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  b) o conteúdo utilizado não violará nem empregará
                  indevidamente os direitos de propriedade intelectual de
                  terceiros;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  c) tem as qualificações, credenciais e experiência exigidas
                  (incluindo formação, treinamento, conhecimento e habilidades);
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  d) garantirá uma qualidade de serviço que corresponda aos
                  padrões de sua indústria e dos serviços em geral;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  e) manterá uma atitude profissional positiva, digna, leal,
                  honesta, de respeito mútuo, confiança e colaboração com os
                  demais parceiros;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  f) agirá com imparcialidade, objetividade, honestidade,
                  respeito, transparência, lealdade e cortesia na relação com
                  administradores, empregados, fornecedores, clientes,
                  acionistas e investidores;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  g) será diligente e responsável na relação com as Autoridades,
                  clientes, concorrentes, fornecedores, membros das comunidades
                  e todos os demais indivíduos, empresas e organizações com que
                  o DPOnet se relaciona no exercício das suas atividades
                  regulares, buscando sempre preservar a boa reputação, imagem e
                  relações da empresa;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  h) tratará os dados pessoais dos Usuários aos quais tiver
                  acesso em decorrência dos Serviços do DPOnet em total respeito
                  à Política de Privacidade e às leis aplicáveis, incluindo a
                  Lei Geral de Proteção de Dados.
                </Typography>
              </Box>
              <Box my={1}>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  Garante que não:
                </Typography>
              </Box>
              <Box my={1}>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  a) fornecerá qualquer conteúdo ou informação de caráter
                  impróprio, ofensivo, racista, agressivo, sexista,
                  pornográfico, falso, enganoso, incorreto, infrator,
                  difamatório ou calunioso;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  b) fará assédio de qualquer natureza, inclusive moral ou
                  sexual, provocando o constrangimento alheio;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  c) transmitirá, sem que seja solicitado ou autorizado,
                  qualquer publicidade, material promocional, lixo eletrônico,
                  spam ou qualquer outra forma de solicitação (comercial ou não)
                  por meio dos serviços ou para qualquer usuário;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  d) se envolverá em qualquer atividade que exija que nós
                  obtenhamos licenças ou paguemos royalties a qualquer terceiro;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  e) se passará por outra pessoa ou obterá acesso não autorizado
                  à conta de outra pessoa;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  f) interferirá com outros parceiros nem os impedirá de
                  fornecer os serviços ou conteúdo deles;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  g) estabelecerá relações comerciais com empresas ou indivíduos
                  que não observem padrões éticos, de saúde e segurança e de
                  direitos humanos;
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  h) irá ofertar, pagar, prometer ou autorizar benefício pessoal
                  (seja pagamento ou qualquer outro tipo de benefício pessoal),
                  direta ou indiretamente, a qualquer funcionário de governo, de
                  qualquer esfera e país.
                </Typography>
              </Box>
            </Box>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  4. A RELAÇÃO CONTRATUAL ENTRE O DPONET PARCEIROS E O PARCEIRO
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>4.1. Relação Contratual.</strong> Os serviços e o conteúdo
              oferecidos pela plataforma são propriedade do DPOnet. Ao
              estabelecer estes Termos de Uso que permitem ao Parceiro o gozo
              das funcionalidades do sistema, o DPOnet está oferecendo uma
              licença de uso, que é não-exclusiva, limitada, revogável e de uso
              pessoal.
            </Typography>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  5. A RELAÇÃO CONTRATUAL ENTRE OS PARCEIROS E AS PESSOAS
                  JURÍDICAS OU PESSOAS FÍSICAS CONTRATANTES
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>5.1. Relação Contratual.</strong> Os Parceiros não têm um
              relacionamento contratual direto com as pessoas jurídicas e
              físicas, que contratarão seus serviços e soluções na plataforma.
              Portanto, a única informação que você receberá sobre elas será o
              que for fornecido por você por meio dos serviços. Você concorda
              que não usará os dados recebidos para qualquer finalidade além de
              oferecer seus serviços a essas empresas na plataforma do DPOnet e
              que não solicitará dados pessoais adicionais nem armazenará os
              dados pessoais das empresas fora da plataforma do DPOnet. Você
              concorda em indenizar o DPOnet por quaisquer reclamações
              decorrentes do uso por você dos dados pessoais das empresas em
              inobservância aos presentes Termos de Uso.
            </Typography>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  6. QUAIS SÃO OS DIREITOS DO DPONET PARCEIROS SOBRE A
                  APLICAÇÃO?
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>6.1. Nossos Direitos.</strong> Todos os direitos relativos
              ao DPOnet Parceiros e suas funcionalidades são de propriedade
              exclusiva do DPOnet, inclusive no que diz respeito aos seus
              textos, imagens, layouts, software, códigos, bases de dados,
              gráficos, artigos, fotografias e demais conteúdos produzidos
              direta ou indiretamente pelo DPOnet Parceiros (“Conteúdo do DPOnet
              Parceiros”). O Conteúdo do DPOnet Parceiros é protegido pela lei
              de direitos autorais e de propriedade intelectual. É proibido
              usar, copiar, reproduzir, modificar, traduzir, publicar,
              transmitir, distribuir, executar, fazer o upload, exibir,
              licenciar, vender ou explorar e fazer engenharia reversa do
              Conteúdo do DPOnet Parceiros, para qualquer finalidade, sem o
              consentimento prévio e expresso do DPOnet Parceiros. Qualquer uso
              não autorizado do Conteúdo do DPOnet Parceiros será considerado
              como violação dos direitos autorais e de propriedade intelectual
              do DPOnet Parceiros.
            </Typography>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  7. PROPRIEDADE INTELECTUAL SOBRE O SOFTWARE E OS MATERIAIS
                  DISPONIBILIZADOS
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>7.1. Propriedade Intelectual.</strong> Para nós do DPOnet,
              a qualidade dos materiais disponibilizados ao usuário é de suma
              importância. A criação deles é fruto de muito trabalho e dedicação
              de nossos desenvolvedores. Por isso, reafirmamos que o DPOnet
              garante que todos os direitos, título e interesse (incluindo, mas
              não apenas, os direitos autorais, marcários e outros de
              propriedade intelectual) sobre o serviço disponibilizado por nós
              permanecerão sob a titularidade do DPOnet.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>7.2. Não-aquisição de Direitos.</strong> O Parceiro não
              adquirirá nenhum direito de propriedade sobre os serviços e
              conteúdos do DPOnet Parceiros, exceto quando haja outorga expressa
              neste Termos de Uso.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>7.3. Marca Comercial.</strong> Você deve e se obriga a:
            </Typography>
            <Box ml={5}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                a) usar somente as imagens da marca comercial que
                disponibilizarmos à você para a finalidade de dar publicidade
                sobre a parceria, ou seja, sua relação de parceiro com o DPOnet;
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                b) usar somente nossa marca comercial em conexão com a promoção
                e oferta do seu serviço prestado no DPOnet ou sua participação
                no DPOnet; e
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                c) acatar imediatamente caso solicitemos que você suspenda o
                uso.
              </Typography>
              <Box my={2}>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="justify"
                  gutterBottom
                >
                  Você não deve e se obriga a não:
                </Typography>
              </Box>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                a) usar nossa marca comercial de maneira enganosa ou
                depreciativa; ou
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                b) usar nossa marca comercial de forma que viole a lei aplicável
                ou em conexão com um tema ou material obsceno, indecente ou
                ilegal.
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>
                7.4. Download de Conteúdo. É proibido que o Parceiro faça o
                download de nosso conteúdo com o intuito de armazená-lo em banco
                de dados para oferecer para terceiro que não seja o próprio
                usuário. Veda-se, também, que o conteúdo disponibilizado por nós
                seja usado para criar uma base de dados ou um serviço que possa
                concorrer de qualquer maneira (parcialmente ou integralmente)
                com o nosso negócio.
              </strong>
            </Typography>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  8. RECLAMAÇÕES SOBRE VIOLAÇÃO DE DIREITO AUTORAL
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>8.1. Infringência de Direitos.</strong> Alegações de
              infringência de direito autoral de qualquer conteúdo disponível no
              DPOnet Parceiros devem ser encaminhadas por meio do e-mail{' '}
              <strong>atendimento@dponet.com.br.</strong>
            </Typography>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  9. RESPONSABILIDADES DO PARCEIRO E DO DPONET PARCEIROS
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>9.1. Responsabilidade pelo Uso.</strong> Você é
              exclusivamente responsável pelo uso do DPOnet Parceiros e deverá
              respeitar as regras destes Termos de Uso, bem como a legislação
              aplicável.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>9.2. Responsabilização por Eventuais Danos.</strong> o
              DPOnet, seu controlador, suas afiliadas, parceiras ou funcionários
              não serão, em hipótese alguma, responsabilizados por danos diretos
              ou indiretos que resultem de, ou que tenham relação com o acesso,
              uso ou a incapacidade de acessar ou utilizar o DPOnet Parceiros.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>
                9.3. Não-Responsabilização. TENDO EM VISTA AS CARACTERÍSTICAS
                INERENTES AO AMBIENTE DA INTERNET, O DPONET NÃO SE
                RESPONSABILIZA POR INTERRUPÇÕES OU SUSPENSÕES DE CONEXÃO,
                TRANSMISSÕES DE COMPUTADOR INCOMPLETAS OU QUE FALHEM, BEM COMO
                POR FALHA TÉCNICA DE QUALQUER TIPO, INCLUINDO, MAS NÃO SE
                LIMITANDO, AO MAU FUNCIONAMENTO ELETRÔNICO DE QUALQUER REDE,
                HARDWARE OU SOFTWARE. A INDISPONIBILIDADE DE ACESSO À INTERNET
                OU AO DPONET PARCEIROS, ASSIM COMO QUALQUER INFORMAÇÃO INCORRETA
                OU INCOMPLETA SOBRE O DPONET PARCEIROS E QUALQUER FALHA HUMANA,
                TÉCNICA OU DE QUALQUER OUTRO TIPO NO PROCESSAMENTO DAS
                INFORMAÇÕES DO DPONET PARCEIROS NÃO SERÃO CONSIDERADAS
                RESPONSABILIDADE DO DPONET. O DPONET SE EXIME DE QUALQUER
                RESPONSABILIDADE PROVENIENTE DE TAIS FATOS E/OU ATOS.
              </strong>
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>9.4. Perda de Lucros.</strong> Quando permitido por lei, o
              DPOnet e os fornecedores ou distribuidores não serão responsáveis
              por perda de lucros, perda de receita, perda de dados, perdas
              financeiras ou por danos indiretos, especiais, consequenciais,
              exemplares ou punitivos.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>9.5. Manutenção.</strong> É de sua inteira
              responsabilidade manter o ambiente de seu dispositivo (computador,
              celular, tablet, entre outros) seguro, com o uso de ferramentas
              disponíveis, como antivírus, firewall, entre outras, de modo a
              contribuir na prevenção de riscos eletrônicos.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>9.6. Links Externos.</strong> É possível que o DPOnet
              Parceiros possa conter links para sites e aplicativos de
              terceiros, assim como ter tecnologias integradas. Isso não
              implica, de maneira alguma, que o DPOnet endossa, verifica,
              garante ou possui qualquer ligação com os proprietários desses
              sites ou aplicativos, não sendo responsável pelo seu conteúdo,
              precisão, políticas, práticas ou opiniões. O DPOnet recomenda que
              você leia os termos de uso e políticas de privacidade de cada site
              de terceiros ou serviço que vier a visitar ou utilizar.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>9.7. Exclusão de Responsabilidade.</strong> O DPOnet
              Parceiros facilita a interação de você com as pessoas jurídicas e
              físicas que contratarão seus serviços e soluções na plataforma. O
              DPONET PARCEIROS ATUA COMO MERO FACILITADOR DA TRANSAÇÃO ENTRE
              VOCÊ E TAIS TERCEIROS, DE MODO QUE o DPONET NÃO É FORNECEDOR DE
              BENS OU SERVIÇOS, OS QUAIS SÃO PRESTADOS DIRETAMENTE POR VOCÊ E
              OUTROS TERCEIROS PRESTADORES INDEPENDENTES. O DPONET NÃO SERÁ, EM
              HIPÓTESE ALGUMA, RESPONSÁVEL POR TAIS PRODUTOS OU SERVIÇOS DE
              TERCEIROS FORNECEDORES, RESGUARDADO SEU DIREITO DE REGRESSO, E
              REITERAMOS A NECESSIDADE DO PARCEIRO LER, ANALISAR E ACEITAR OS
              TERMOS DE USO DAS PLATAFORMAS QUE POSSAM TER ALGUMA INTERFACE
              CONOSCO.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>9.8. Regresso.</strong> Caso o DPOnet venha a ser
              compelido a responder por quaisquer obrigações do Parceiro, de
              seus empregados ou prestadores de serviços, o Parceiro se obriga a
              indenizar integralmente o DPOnet pelo valor que vier a ser
              despendido, diretamente, por meio de acordo extrajudicial ou com a
              promoção da competente ação de regresso. Admite-se, ainda, ao
              DPOnet, valer-se de quaisquer das modalidades de intervenção de
              terceiros estabelecidas pela legislação processual civil.
            </Typography>
            <Box ml={5} mt={1}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>9.8.1. Autocompensação.</strong> Eventuais saldos e
                créditos disponíveis na plataforma para resgate do Parceiro,
                poderão ser utilizados para compensar eventuais pagamentos de
                indenizações ou ressarcimentos devidos pelo Parceiro.
              </Typography>
            </Box>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  10. COMO O DPONET PARCEIROS LIDA COM O CONTEÚDO QUE VOCÊ E
                  OUTROS USUÁRIOS PRODUZEM?
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>10.1. Criação de Conteúdo.</strong> O DPOnet Parceiros
              poderá, a seu exclusivo critério, permitir que você ou qualquer
              outro usuário apresente, carregue, publique ou disponibilize, na
              aplicação, conteúdo ou informações de texto, imagem, áudio ou
              vídeo (“
              <strong>Conteúdo de Usuário</strong>”).
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>10.2. Conteúdos Proibidos.</strong> É proibido qualquer
              Conteúdo de Usuário de caráter difamatório, calunioso, injurioso,
              violento, pornográfico, obsceno, ofensivo ou ilícito, conforme
              apuração do DPOnet Parceiros a seu critério exclusivo, inclusive
              informações de propriedade exclusiva pertencentes a outras pessoas
              ou empresas (ex. direito de autor, marcas), sem a expressa
              autorização do titular desses direitos, cuja violação não será de
              responsabilidade do DPOnet Parceiros.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>10.3. Titularidade do Conteúdo.</strong> Qualquer Conteúdo
              de Usuário fornecido por você permanece de sua propriedade.
              Contudo, ao fornecê-lo para o DPOnet, você nos outorga uma licença
              em nível mundial, por prazo indeterminado, gratuita e
              transferível, e com direito a sublicenciar, usar, copiar,
              modificar, criar obras derivadas, distribuir, publicar, exibir
              esse Conteúdo de Usuário em todos os formatos e canais de
              distribuição possíveis, sem necessidade de novo aviso a você, e
              sem necessidade de qualquer pagamento, desde que isso esteja
              relacionado ao funcionamento da plataforma.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>10.4. Exclusão do Conteúdo.</strong> O DPOnet Parceiros
              poderá, mas não se obriga a, analisar, monitorar e remover
              Conteúdo de Usuário, a critério exclusivo do DPOnet Parceiros, a
              qualquer momento e por qualquer motivo, sem nenhum aviso a você.
            </Typography>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>11. CONTRATAÇÃO E PAGAMENTO</strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>11.1. Contratação.</strong> A pessoa jurídica ou física
              contratante solicitará o serviço/solução via formulário de
              atendimento que será recebido inicialmente pelo DPOnet por meio do
              seu canal de atendimento. A solicitação será encaminhada pelo
              DPOnet para o e-mail cadastrado por você. Você deverá realizar um
              contato inicial em até 12 (doze) horas úteis com o possível
              contratante, sendo que o não cumprimento deste prazo poderá
              acarretar em sua suspensão ou desligamento da plataforma.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>11.2. Pagamento.</strong> O DPOnet se reserva o direito de
              reter 30% (trinta por cento) sobre o valor líquido dos serviços ou
              solução oferecidos por você. O valor de 30% (trinta por cento)
              deverá ser transferido para a conta bancária CNPJ:
              36.487.128/0001-79 (PIX); Banco Bradesco S/A (237); Agência: 0002;
              Conta Corrente: 35035-4, no prazo de 2 (dois) dias úteis contados
              do recebimento por você. Você se obriga, também, a encaminhar um
              e-mail para atendimento@dponet.com.br, com o comprovante de
              transferência para o DPOnet, bem como o comprovante fiscal da
              solução ou serviço prestado.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>11.3. Confiança e Segurança.</strong> Nós nos reservamos o
              direito de suspender pagamentos e/ou banir Parceiros por qualquer
              motivo e a qualquer momento, sem aviso prévio, incluindo nos casos
              em que:
            </Typography>
            <Box my={2} ml={5}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                a) o Parceiro não cumpra nossas políticas ou termos legais;
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                b) o serviço prestado esteja abaixo dos nossos padrões de
                qualidade ou tenha um impacto negativo na experiência do
                cliente;
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                c) o Parceiro apresente um comportamento que possa se refletir
                desfavoravelmente ao DPOnet ou levar a marca a descrédito
                público, desonra, escândalo ou ridículo;
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                d) o Parceiro use os serviços de maneira que constitua
                concorrência desleal, como para promover seus negócios,
                diretamente ou por meio de terceiros interpostos, fora da
                plataforma; ou
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                e) conforme determinado pelo DPOnet a seu exclusivo critério.
              </Typography>
            </Box>
            <Box my={2}>
              <Typography
                variant="body1"
                color="secondary"
                align="justify"
                gutterBottom
              >
                <strong>
                  12. O QUE MAIS EU PRECISO SABER SOBRE ESTES TERMOS DE USO?
                </strong>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>12.1. Alterações.</strong> Para melhorar sua experiência,
              O DPOnet Parceiros está sempre sendo atualizado. Por esse motivo,
              estes Termos de Uso podem ser alterados, a qualquer tempo, a fim
              de refletir os ajustes realizados. No entanto, sempre que ocorrer
              qualquer modificação, você será previamente informado pelo
              endereço de e-mail fornecido por você no momento do cadastro ou
              por um aviso em destaque na aplicação. Caso você não concorde com
              os novos Termos de Uso, você poderá rejeitá-los, mas,
              infelizmente, isso significa que você não poderá mais ter acesso e
              fazer uso do DPOnet Parceiros. Se de qualquer maneira você
              utilizar o DPOnet Parceiros mesmo após a alteração destes Termos
              de Uso, isso significa que{' '}
              <strong>você concorda com todas as modificações.</strong>
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>
                12.2. Conflito entre Disposições. Em caso de conflito entre
                estes termos e os termos modificados, os termos posteriores
                prevalecerão com relação a esse conflito.
              </strong>
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>12.3. Lei e Foro.</strong> Estes Termos de Uso são regidos
              pelas leis da República Federativa do Brasil. Quaisquer dúvidas e
              situações não previstas nestes Termos de Uso serão primeiramente
              resolvidas pelo DPOnet Parceiros e, caso persistam, deverão ser
              solucionadas pelo Foro da Comarca de Marília, São Paulo, com
              exclusão de qualquer outro, por mais privilegiado que seja ou
              venha a ser.
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="justify"
              gutterBottom
            >
              <strong>12.4. Dúvidas.</strong> Caso você tenha alguma dúvida,
              comentário ou sugestão, por favor, entre em contato conosco por
              meio do e-mail <strong>atendimento@dponet.com.br.</strong>
            </Typography>
          </PerfectScrollbar>
        </Box>
      </Label>
      <Box mt={4}>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedTerms}
              onChange={handleChange}
              name="termsCheck"
              color="primary"
            />
          }
          label="Aceitar os termos de uso"
        />
      </Box>
    </Box>
  )
}

export default UseTerms
