import { makeStyles } from '@material-ui/core'

const styles = (overFlowSize) => {
  return makeStyles((theme) => ({
    overFlow: {
      display: 'block',
      maxWidth: overFlowSize,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }))
}

export default styles
