import { useCallback, useState, useEffect } from 'react'

import { Grid, Avatar, RootRef, Box, Typography } from '@material-ui/core'

import { useDropzone } from 'react-dropzone'

import useStyles from './styles'

import { Upload } from 'react-feather'

import { useOnboard, useAuth } from 'hooks'

import * as service from 'service'
import { getBaseURL } from 'service/env'

const LogoInputComponent = ({ logo }) => {
  const [logoFile, setLogoFile] = useState(null)
  const [image, setImage] = useState(
    logo ? getBaseURL('marketPlace') + logo : '',
  )

  const classes = useStyles()
  const { toNextStep, partnerIsCreated } = useOnboard()
  const auth = useAuth()
  const onDrop = useCallback((acceptedFile) => {
    setLogoFile(acceptedFile[0])
    setImage(URL.createObjectURL(acceptedFile[0]))
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()

  const handleSubmit = async () => {
    if (logoFile && partnerIsCreated) {
      await service.marketPlace.partner.addLogo(logoFile)
      auth.loadData()
    }
  }

  useEffect(() => {
    handleSubmit()
    // eslint-disable-next-line
  }, [toNextStep, partnerIsCreated])

  return (
    <Box mb={3}>
      <Grid container>
        <Grid item md={2} xs={12} className={classes.logoContainer}>
          <Avatar src={image} className={classes.logoBox} />
        </Grid>
        <Grid item md={10} xs={12}>
          <RootRef rootRef={ref}>
            <Box {...rootProps} className={classes.inputImageContainer} mb={3}>
              <input {...getInputProps()} />
              <Box>
                <Upload />
              </Box>
              <Box>
                <Typography>Arraste e solte ou selecione uma logo</Typography>
              </Box>
            </Box>
          </RootRef>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LogoInputComponent
