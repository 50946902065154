const SERVICES = {
  CREATE: 'create_partner_services',
  LIST: 'list_partner_services',
  UPDATE: 'update_partner_services',
  DELETE: 'delete_partner_services',
  SHOW: 'show_partner_services',
}

const permissions = {
  SERVICES,
}

export default permissions
