const INACTIVE_STATUS = 'INACTIVE'
const ACTIVE_STATUS = 'ACTIVE'
const WAITING_APPROVAL_STATUS = 'WAITING_APPROVAL'
const REPROVED_STATUS = 'REPROVED'
const DISABLED_STATUS = 'DISABLED'

const PRESENTIAL_TYPE = 'PRESENTIAL'
const HYBRID_TYPE = 'HYBRID'
const REMOTE_TYPE = 'REMOTE'

const PRESENTIAL_LABEL = 'Presencial'
const HYBRID_LABEL = 'Hibrido'
const REMOTE_LABEL = 'Remoto'

const TABS = [
  {
    value: ACTIVE_STATUS,
    label: 'Ativos',
  },
  {
    value: WAITING_APPROVAL_STATUS,
    label: 'Aguardando aprovação',
  },
  {
    value: REPROVED_STATUS,
    label: 'Reprovados',
  },
  {
    value: INACTIVE_STATUS,
    label: 'Inativos',
  },
  {
    value: DISABLED_STATUS,
    label: 'Desabilitados',
  },
]

const DETAILS_TABS = [
  {
    value: 'clients',
    label: 'Clientes que contrataram o serviço',
  },
  {
    value: 'documents',
    label: 'Documentos',
  },
]

const DELETE_DIALOG_INFO_MESSAGE =
  'Ao excluir um serviço o mesmo deixará de existir e não poderá ser recuperado.'

const CHANGE_STATUS_DIALOG_INFO_MESSAGE_INACTIVATE =
  'Ao inativar um serviço o mesmo deixará de ser exibido para que novos clientes realizem a contratação do mesmo, ' +
  'porém, a inativação não impactará em quaisquer alterações em clientes que possuem um contrato ativo com este serviço.'

const CHANGE_STATUS_DIALOG_INFO_MESSAGE_ACTIVATE =
  'Ao ativar este serviço o mesmo ficará visível para que os clientes o contratem.'

const CHANGE_STATUS_DIALOG_INFO_MESSAGE_WAITING_APPROVAL =
  'Ao enviar um serviço reprovado para revisão você está solicitando uma nova análise da nossa equipe sobre seu serviço, ' +
  'é muito importante que você tenha se adequado as expectativas solicitadas no motivo da reprova do serviço antes de o enviar para revisão novamente.'

const CHANGE_STATUS_DIALOG_INFO_MESSAGES = {
  ACTIVE: CHANGE_STATUS_DIALOG_INFO_MESSAGE_INACTIVATE,
  INACTIVE: CHANGE_STATUS_DIALOG_INFO_MESSAGE_ACTIVATE,
  REPROVED: CHANGE_STATUS_DIALOG_INFO_MESSAGE_WAITING_APPROVAL,
}

const CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT = {
  ACTIVE: 'Inativar',
  INACTIVE: 'Ativar',
  REPROVED: 'Enviar para análise',
}

const CHANGE_STATUS_VALUE = {
  ACTIVE: INACTIVE_STATUS,
  INACTIVE: ACTIVE_STATUS,
  REPROVED: WAITING_APPROVAL_STATUS,
}

const CHANGE_STATUS_DIALOG_TITLES = {
  ACTIVE: 'Inativar serviço',
  INACTIVE: 'Ativar serviço',
  REPROVED: 'Enviar serviço para análise',
}

const CHANGE_STATUS_SUCCESS_MESSAGES = {
  ACTIVE: 'Serviço inativado com sucesso!',
  INACTIVE: 'Serviço ativado com sucesso!',
  REPROVED: 'Serviço enviado para análise com sucesso!',
}

const SERVICE_MODALITY = {
  [PRESENTIAL_TYPE]: PRESENTIAL_LABEL,
  [HYBRID_TYPE]: HYBRID_LABEL,
  [REMOTE_TYPE]: REMOTE_LABEL,
}

const partnerService = {
  TABS,
  PRESENTIAL_TYPE,
  HYBRID_TYPE,
  REMOTE_TYPE,
  PRESENTIAL_LABEL,
  HYBRID_LABEL,
  REMOTE_LABEL,
  SERVICE_MODALITY,
  DETAILS_TABS,
  ACTIVE_STATUS,
  DISABLED_STATUS,
  INACTIVE_STATUS,
  REPROVED_STATUS,
  CHANGE_STATUS_VALUE,
  WAITING_APPROVAL_STATUS,
  DELETE_DIALOG_INFO_MESSAGE,
  CHANGE_STATUS_DIALOG_TITLES,
  CHANGE_STATUS_SUCCESS_MESSAGES,
  CHANGE_STATUS_DIALOG_INFO_MESSAGES,
  CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT,
}

export default partnerService
