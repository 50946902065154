import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'

import { Label } from 'components'

import { useSnackbar } from 'hooks'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

const ContactsDialog = ({
  contact,
  open,
  orderId,
  orderStatus,
  currentTab,
  onEvent,
  setOpen = () => {},
}) => {
  const snackbar = useSnackbar()

  const handleChangeStatus = async (id, cancelType = false) => {
    const status = cancelType
      ? constants.order.status.CANCELED_STATUS
      : helpers.orders.updateStatus(currentTab)

    try {
      await service.marketPlace.orders.changeStatus(id, {
        order: { status },
      })
      snackbar.open({
        message: 'Status atualizado com sucesso',
        variant: 'success',
      })
      setOpen(false)
      onEvent()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h5">Detalhes do contato</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <Label title="Identificador">
                <Typography variant="body1" color="secondary">
                  {contact?.id}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Telefone">
                <Typography variant="body1" color="secondary">
                  {helpers.formatters.phone(contact?.phone) || '-'}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Email">
                <Typography variant="body1" color="secondary">
                  {contact?.email || '-'}
                </Typography>
              </Label>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box px={2} display="flex" justifyContent="flex-end" width="100%">
          {currentTab === constants.order.status.PENDING_STATUS && (
            <Box pl={2} pr={1}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => handleChangeStatus(orderId, true)}
              >
                Cancelar
              </Button>
            </Box>
          )}
          <Box pl={2} pr={1}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => handleChangeStatus(orderId)}
            >
              {helpers.orders.labelTextButton(orderStatus)}
            </Button>
          </Box>
          <Box pl={2} pr={1}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ContactsDialog
