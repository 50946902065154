import splitSiteUrlToProtocol from './splitSiteUrlToProtocol'
import chipStatusColor from './chipStatusColor'
import statusLabel from './statusLabel'

const partners = {
  splitSiteUrlToProtocol,
  chipStatusColor,
  statusLabel,
}

export default partners
