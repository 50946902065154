import { useContext } from 'react'

import MyInformationContext from 'contexts/MyInformationContext'

const useMyInformation = () => {
  const context = useContext(MyInformationContext)

  if (context === undefined) {
    throw new Error(
      'useMyInformation must be used within a MyInformation.Provider',
    )
  }

  return {
    responseAddresses: context.responseAddresses,
    responseContacts: context.responseContacts,
    responseServices: context.responseServices,
    currentTabResponse: context.currentTabResponse,
    loadData: context.loadData,
    searchWithPagination: context.searchWithPagination,
    loadingProvider: context.loadingProvider,
    currentTab: context.currentTab,
    setCurrentTab: context.setCurrentTab,
  }
}

export default useMyInformation
