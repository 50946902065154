import auth from './auth'
import user from './user'
import partner from './partner'
import contact from './contact'
import address from './address'
import partnerService from './partnerService'
import companyService from './companyService'
import showcase from './showcase'
import serviceCategories from './serviceCategories'
import orders from './orders'

const marketPlace = {
  auth,
  user,
  partner,
  contact,
  address,
  partnerService,
  companyService,
  showcase,
  serviceCategories,
  orders,
}

export default marketPlace
