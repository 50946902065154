import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Box } from '@material-ui/core'

import { Permitted, TopBar } from 'components'
import { OnboardProvider } from 'providers'
import useStyles from './styles'
import { routes } from 'Routes'

const Onboard = ({ children, permission }) => {
  const classes = useStyles()
  return (
    <OnboardProvider>
      <Permitted tag={permission} fallback={<Redirect to={routes.forbidden} />}>
        <Box className={classes.root}>
          <TopBar />
          <Box className={classes.content}>{children}</Box>
        </Box>
      </Permitted>
    </OnboardProvider>
  )
}

Onboard.propTypes = {
  children: PropTypes.any,
}

export default Onboard
