import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import {
  AppBar,
  Chip,
  IconButton,
  Toolbar,
  SvgIcon,
  Box,
  makeStyles,
  Hidden,
  Tooltip,
} from '@material-ui/core'

import { Account } from './components'

import { Menu as MenuIcon, HelpCircle as HelpCircleIcon } from 'react-feather'

import styles from './styles'
import Logo from 'images/dponet-logo-white.svg'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const TopBar = ({
  className,
  onMobileNavOpen = () => {},
  withSidebar = false,
  ...rest
}) => {
  const classes = useStyles()

  const handleHelp = () => {
    window.open(
      'https://immunizesystem.desk360.com.br/ajuda/search?filter[category_id]=610b494a34886a7ca2a3b800',
      '_blank',
    )
  }

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box>
          {withSidebar ? (
            <>
              <Hidden mdDown>
                <RouterLink to={routes.home}>
                  <img
                    alt="Logo"
                    src={Logo}
                    className={classes.logo}
                    width="100%"
                  />
                </RouterLink>
              </Hidden>
              <Hidden lgUp>
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  onClick={onMobileNavOpen}
                >
                  <SvgIcon fontSize="small">
                    <MenuIcon />
                  </SvgIcon>
                </IconButton>
              </Hidden>
            </>
          ) : (
            <RouterLink to={routes.home}>
              <img
                alt="Logo"
                src={Logo}
                className={classes.logo}
                width="100%"
              />
            </RouterLink>
          )}
        </Box>
        <Box ml={2} flexGrow={1} />
        <Box display="flex" justifyContent="flex-end" mr={5}>
          <Tooltip title="Acesso a documentação">
            <Chip
              className={classes.helpChip}
              onClick={() => handleHelp()}
              icon={<HelpCircleIcon className={classes.iconChip} />}
              label="Ajuda"
              clickable={false}
            />
          </Tooltip>
        </Box>
        <Account />
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
