import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.order.status.REQUESTED_STATUS:
      return 'SOLICITADO'
    case constants.order.status.PENDING_STATUS:
      return 'EM ANDAMENTO'
    case constants.order.status.ACTIVED_STATUS:
      return 'ATIVO'
    case constants.order.status.CANCELED_STATUS:
      return 'CANCELADO'
    default:
      return null
  }
}

export default statusLabel
