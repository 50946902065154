import constants from 'constants/index'

const labelTextButton = (status) => {
  switch (status) {
    case constants.order.status.REQUESTED_STATUS:
      return 'Confirmar recebimento'
    case constants.order.status.PENDING_STATUS:
      return 'Confirmar ativação'
    case constants.order.status.ACTIVED_STATUS:
      return 'Confirmar cancelamento'
    default:
      return null
  }
}

export default labelTextButton
