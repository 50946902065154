import { TextField, Box } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import helpers from 'helpers'

import schema from './schema'

const ContactForm = ({ onSubmit, contact }) => {
  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      email: contact?.email ?? '',
      phone: helpers.formatters.phone(contact?.phone) ?? '',
      description: contact?.description ?? '',
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="contact-form">
      <Controller
        control={control}
        name="email"
        as={
          <TextField
            label="Email"
            color="primary"
            variant="outlined"
            error={!!errors.email}
            helperText={errors?.email?.message}
            fullWidth
          />
        }
        mode="onBlur"
      />
      <Box mt={2}>
        <Controller
          control={control}
          name="phone"
          as={
            <PhoneInput
              specialLabel="Telefone"
              color="primary"
              country="br"
              variant="outlined"
              enableAreaCodeStretch
              error={!!errors.phone}
              helperText={errors?.phone?.message}
              inputStyle={{
                width: '100%',
              }}
            />
          }
          mode="onBlur"
        />
      </Box>
      <Box mt={2}>
        <Controller
          control={control}
          name="description"
          as={
            <TextField
              label="Descrição"
              color="primary"
              variant="outlined"
              error={!!errors.description}
              helperText={errors?.description?.message}
              fullWidth
            />
          }
          mode="onBlur"
        />
      </Box>
    </form>
  )
}

export default ContactForm
