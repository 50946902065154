import { useState } from 'react'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import {
  LoadingBox,
  MenuButton,
  Permitted,
  BaseActionDialog as DeletePartnerServiceDialog,
  BaseActionDialog as ChangeStatusPartnerServiceDialog,
} from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import * as service from 'service'

const TableRowPartnerServices = ({
  partnerService,
  setLoading = () => {},
  onEvent = () => {},
  ...rest
}) => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const [deletePartnerServiceDialog, setDeletePartnerServiceDialog] =
    useState(false)
  const [
    changeStatusPartnerServiceDialog,
    setChangeStatusPartnerServiceDialog,
  ] = useState(false)

  const handleEdit = (partnerServiceId) => {
    history.push(
      reverse(routes.services.edit, {
        serviceId: partnerServiceId,
      }),
    )
  }

  const handleDetails = (partnerServiceId) => {
    history.push(
      reverse(routes.services.show, {
        serviceId: partnerServiceId,
      }),
    )
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await service.marketPlace.partnerService.destroy({
        partnerServiceId: partnerService.id,
      })
      snackbar.open({
        message: 'Serviço excluído com sucesso',
        variant: 'success',
      })
      setDeletePartnerServiceDialog(false)
      setLoading(false)
      onEvent()
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const handleChangeStatus = async (partnerService) => {
    const statusLabel =
      partnerService.status === constants.partnerService.ACTIVE_STATUS
        ? 'inativado'
        : 'ativado'
    const newStatus =
      partnerService.status === constants.partnerService.ACTIVE_STATUS
        ? constants.partnerService.INACTIVE_STATUS
        : constants.partnerService.ACTIVE_STATUS

    setLoading(true)
    try {
      await service.marketPlace.partnerService.update({
        ...partnerService,
        partnerServiceId: partnerService.id,
        status: newStatus,
      })
      snackbar.open({
        message: `Serviço ${statusLabel} com sucesso`,
        variant: 'success',
      })
      setDeletePartnerServiceDialog(false)
      setLoading(false)
      onEvent()
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const buttonLabelChangeStatus =
    partnerService.status === constants.partnerService.ACTIVE_STATUS
      ? 'Inativar'
      : 'Ativar'
  return (
    <>
      <TableRow {...rest}>
        <TableCell>{partnerService.id}</TableCell>
        <TableCell>{partnerService.companyService.name}</TableCell>
        <TableCell>{partnerService.description}</TableCell>
        <TableCell>
          {helpers.partnerServices.formatPrice(partnerService)}
        </TableCell>
        <TableCell>
          {helpers.formatters.date(partnerService.createdAt)}
        </TableCell>
        <Permitted
          someTags={[
            constants.permissions.SERVICES.UPDATE,
            constants.permissions.SERVICES.SHOW,
            constants.permissions.SERVICES.DELETE,
          ]}
        >
          <TableCell align="right" disableTooltip>
            <MenuButton>
              <Permitted tag={constants.permissions.SERVICES.SHOW}>
                <Button
                  fullWidth
                  onClick={() => handleDetails(partnerService.id)}
                >
                  Detalhes
                </Button>
              </Permitted>
              {![
                constants.partnerService.WAITING_APPROVAL_STATUS,
                constants.partnerService.DISABLED_STATUS,
              ].includes(partnerService.status) && (
                <Permitted tag={constants.permissions.SERVICES.UPDATE}>
                  <Button
                    fullWidth
                    onClick={() => handleEdit(partnerService.id)}
                  >
                    Editar
                  </Button>
                </Permitted>
              )}
              {[
                constants.partnerService.INACTIVE_STATUS,
                constants.partnerService.ACTIVE_STATUS,
              ].includes(partnerService.status) && (
                <Permitted tag={constants.permissions.SERVICES.UPDATE}>
                  <Button
                    fullWidth
                    onClick={() => setChangeStatusPartnerServiceDialog(true)}
                  >
                    {buttonLabelChangeStatus}
                  </Button>
                </Permitted>
              )}
              <Permitted tag={constants.permissions.SERVICES.DELETE}>
                <Button
                  fullWidth
                  onClick={() => setDeletePartnerServiceDialog(true)}
                >
                  Excluir
                </Button>
              </Permitted>
            </MenuButton>
          </TableCell>
        </Permitted>
      </TableRow>
      <DeletePartnerServiceDialog
        title="Excluir serviço"
        infoMessage={constants.partnerService.DELETE_DIALOG_INFO_MESSAGE}
        actionButtonText="Excluir"
        maxWidth="sm"
        open={deletePartnerServiceDialog}
        setOpen={setDeletePartnerServiceDialog}
        action={handleDelete}
      />
      <ChangeStatusPartnerServiceDialog
        title={
          constants.partnerService.CHANGE_STATUS_DIALOG_TITLES[
            partnerService.status
          ]
        }
        infoMessage={
          constants.partnerService.CHANGE_STATUS_DIALOG_INFO_MESSAGES[
            partnerService.status
          ]
        }
        actionButtonText={
          constants.partnerService.CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT[
            partnerService.status
          ]
        }
        maxWidth="sm"
        open={changeStatusPartnerServiceDialog}
        setOpen={setChangeStatusPartnerServiceDialog}
        action={() => handleChangeStatus(partnerService)}
      />
    </>
  )
}

const PartnerServicesTable = ({
  partnerServices,
  isLoading,
  onEvent = () => {},
  setLoading = () => {},
}) => {
  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum serviço encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow height={55}>
                  <TableCell width="15%">Identificador</TableCell>
                  <TableCell width="20%">Nome do serviço</TableCell>
                  <TableCell width="30%">Descrição</TableCell>
                  <TableCell width="10%">Preço</TableCell>
                  <TableCell width="15%">Data de criação</TableCell>
                  <Permitted
                    someTags={[
                      constants.permissions.SERVICES.UPDATE,
                      constants.permissions.SERVICES.DELETE,
                      constants.permissions.SERVICES.SHOW,
                    ]}
                  >
                    <TableCell width="10%" align="right">
                      Ações
                    </TableCell>
                  </Permitted>
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerServices?.map((partnerService) => (
                  <TableRowPartnerServices
                    partnerService={partnerService}
                    key={partnerService.id}
                    setLoading={setLoading}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

export default PartnerServicesTable
