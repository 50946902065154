import { useContext } from 'react'

import SelectOptionsContext from 'contexts/SelectOptionsContext'

const useSelectOptions = () => {
  const context = useContext(SelectOptionsContext)

  if (context === undefined) {
    throw new Error(
      'useDataProcessOptions must be used within a SelectOptionsContext.Provider',
    )
  }

  return {
    companyServices: context.companyServices,
    unformattedCompanyServices: context.unformattedCompanyServices,
    loadData: context.loadData,
    reload: context.reload,
    setReload: context.setReload,
    isLoadingOptions: context.isLoadingOptions,
    formatOptionsForAutocomplete: context.formatOptionsForAutocomplete,
  }
}

export default useSelectOptions
