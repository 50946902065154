import constants from 'constants/index'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.partnerService.ACTIVE_STATUS:
      return classes.chipActive
    case constants.partnerService.INACTIVE_STATUS:
      return classes.chipInactive
    case constants.partnerService.WAITING_APPROVAL_STATUS:
      return classes.chipWaitingApproval
    case constants.partnerService.REPROVED_STATUS:
      return classes.chipReproved
    case constants.partnerService.DISABLED_STATUS:
      return classes.chipDisabled
    default:
      return null
  }
}

export default chipStatusColor
