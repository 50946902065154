import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ ...params }) => {
  return await marketPlaceAPI.get('/orders', { params })
}

const changeStatus = async (id, params) => {
  return await marketPlaceAPI.put(`/orders/${id}/change_status`, params)
}

const orders = {
  get,
  changeStatus,
}

export default orders
