import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { AnimatedLoading as AnimatedLoadingLayout } from 'layouts'

import * as service from 'service'
import { routes } from 'Routes'
import { useAuth } from 'hooks'
import partnerConstants from 'constants/partner'

const DefaultComponent = ({ children }) => <>{children}</>

const RouteWithLayout = ({
  auth,
  layout,
  provider,
  component: Component,
  permission,
  somePermissions,
  admin = false,
  redirect = true,
  animatedLoading = false,
  ...rest
}) => {
  const Layout = layout || DefaultComponent
  const Provider = provider || DefaultComponent
  const AnimatedLoading = animatedLoading
    ? AnimatedLoadingLayout
    : DefaultComponent

  const isAuthenticated = service.marketPlace.auth.isAuthenticated()

  const { user } = useAuth()

  const routeRedirect = () => {
    if (isAuthenticated && user) {
      if (
        [
          partnerConstants.WAITING_APPROVAL_STATUS,
          partnerConstants.DISABLED_STATUS,
        ].includes(user?.partner?.status) &&
        rest.path !== routes.users.unauthorized
      ) {
        service.history.push(routes.users.unauthorized)
        return null
      }
      if (rest.path !== routes.onboard) {
        if (
          !user.partner ||
          user?.partner?.status === partnerConstants.ONBOARD_STATUS ||
          user?.partner?.status === partnerConstants.REPROVED_STATUS
        ) {
          service.history.push(routes.onboard)
          return null
        }
      } else {
        if (
          user.partner &&
          user?.partner?.status !== partnerConstants.ONBOARD_STATUS &&
          user?.partner?.status !== partnerConstants.REPROVED_STATUS
        ) {
          service.history.push(routes.home)
          return null
        }
      }

      if (!auth && redirect) {
        service.history.push(routes.home)
        return null
      }
    }

    if (auth && !isAuthenticated) {
      service.history.push(routes.root)
      return null
    }
  }

  const renderRoute = (matchProps) => {
    return (
      <AnimatedLoading>
        <Layout
          permission={permission}
          somePermissions={somePermissions}
          admin={admin}
        >
          <Provider component={Provider}>
            <Component {...matchProps} />
            {/* <Box
              diplay="flex"
              margin="20px"
              position="fixed"
              zIndex="99999"
              bottom="0"
              right="0"
            >
              <Tooltip title="Acessar documentação dessa página">
                <Fab
                  size="small"
                  color="secondary"
                  onClick={() => window.open('www.google.com.br')}
                >
                  <HelpIcon />
                </Fab>
              </Tooltip>
            </Box> */}
          </Provider>
        </Layout>
      </AnimatedLoading>
    )
  }

  useEffect(() => {
    routeRedirect()
  })

  return <Route {...rest} render={(matchProps) => renderRoute(matchProps)} />
}

RouteWithLayout.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  path: PropTypes.string,
  permission: PropTypes.string,
  admin: PropTypes.bool,
}

export default RouteWithLayout
