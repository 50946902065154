import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default styles
