const ACTIVE_STATUS = 'ACTIVE'
const INACTIVE_STATUS = 'INACTIVE'
const ONBOARD_STATUS = 'ONBOARD'
const WAITING_APPROVAL_STATUS = 'WAITING_APPROVAL'
const DISABLED_STATUS = 'DISABLED'
const REPROVED_STATUS = 'REPROVED'

const ADDRESSES_TAB = 'ADDRESSES'
const CONTACTS_TAB = 'CONTACTS'
const SERVICES_TAB = 'SERVICES'

const MY_INFORMATION_TABS = [
  {
    value: ADDRESSES_TAB,
    label: 'Endereços',
  },
  {
    value: CONTACTS_TAB,
    label: 'Contatos',
  },
  {
    value: SERVICES_TAB,
    label: 'Serviços',
  },
]

const CHANGE_STATUS_VALUE = {
  ACTIVE: INACTIVE_STATUS,
  INACTIVE: ACTIVE_STATUS,
}

const CHANGE_STATUS_DIALOG_TITLES = {
  ACTIVE: 'Inativar cadastro',
  INACTIVE: 'Ativar cadastro',
}

const CHANGE_STATUS_SUCCESS_MESSAGES = {
  ACTIVE: 'Cadastro inativado com sucesso',
  INACTIVE: 'Cadastro ativado com sucesso',
}

const CHANGE_STATUS_DIALOG_INFO_MESSAGE_ACTIVE =
  'Ao inativar seu cadastro você deixará de ser exibido em nossa vitrine de parceiros, porém ainda será possível utilizar a plataforma e reativar seu cadastro a qualquer momento.'

const CHANGE_STATUS_DIALOG_INFO_MESSAGE_INACTIVE =
  'Ao reativar seu cadastro você irá voltar a ser exibido em nossa vitrine de parceiros.'

const CHANGE_STATUS_DIALOG_INFO_MESSAGES = {
  ACTIVE: CHANGE_STATUS_DIALOG_INFO_MESSAGE_ACTIVE,
  INACTIVE: CHANGE_STATUS_DIALOG_INFO_MESSAGE_INACTIVE,
}

const CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT = {
  ACTIVE: 'Inativar',
  INACTIVE: 'Ativar',
}

const partner = {
  ADDRESSES_TAB,
  CONTACTS_TAB,
  SERVICES_TAB,
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  ONBOARD_STATUS,
  WAITING_APPROVAL_STATUS,
  DISABLED_STATUS,
  REPROVED_STATUS,
  MY_INFORMATION_TABS,
  CHANGE_STATUS_VALUE,
  CHANGE_STATUS_SUCCESS_MESSAGES,
  CHANGE_STATUS_DIALOG_TITLES,
  CHANGE_STATUS_DIALOG_INFO_MESSAGES,
  CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT,
}

export default partner
