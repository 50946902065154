import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ ...params }) => {
  return await marketPlaceAPI.get('/service_categories', { params })
}

const serviceCategories = {
  get,
}

export default serviceCategories
