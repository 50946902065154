import formatters from './formatters'
import partners from './partners'
import partnerServices from './partnerServices'
import logs from './logs'
import orders from './orders'

const helpers = {
  formatters,
  partners,
  partnerServices,
  logs,
  orders,
}

export default helpers
