const SEGMENTS = [
  'ADMINISTRADORAS DE CONDOMÍNIO',
  'ASSOCIAÇÃO COMERCIAL',
  'CLÍNICA MÉDICA',
  'COMÉRCIO',
  'CONCESSIONÁRIA DE CARROS',
  'CONCESSIONÁRIA DE MOTOS',
  'CONDOMÍNIO',
  'CONTABILIDADE',
  'CORRETORA DE SEGUROS',
  'DESENVOLVEDOR DE SOFTWARE',
  'E-COMMERCE',
  'ESCOLAS',
  'ESCRITÓRIO DE ADVOCACIA',
  'FARMÁCIA',
  'HOSPITAL',
  'INDÚSTRIA AUTOMOTIVA',
  'INDÚSTRIA METALÚRGICA',
  'INDÚSTRIA QUÍMICA',
  'INDUSTRIA TÊXIL',
  'LABORATÓRIO DE ANÁLISE CLÍNICA',
  'MEDÍCINA OCUPACIONAL',
  'OPERADORA DE PLANO DE SAÚDE',
  'PRESTAÇÃO DE SERVIÇO DE PORTARIA',
  'PROVEDOR DE INTERNET',
]

const landingPage = { SEGMENTS }

export default landingPage
