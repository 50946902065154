const splitSiteUrlToProtocol = (siteUrl) => {
  if (!siteUrl) return { protocol: null, siteUrl: null }

  const protocolIndex = siteUrl?.search('://') + 3

  return {
    protocol: siteUrl.slice(0, protocolIndex),
    siteUrl: siteUrl.slice(protocolIndex),
  }
}

export default splitSiteUrlToProtocol
