import React from 'react'
import {
  Container,
  Box,
  Paper,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core'

import { ContentHeader } from 'components'
import { useOnboard } from 'hooks'
import { Stepper, Steps, StepperButtons } from './components'
import useStyles from './styles'

const Onboard = () => {
  const classes = useStyles()
  const { activeStep, isPartnerReproved } = useOnboard()

  const steps = [
    'Dados do perfil',
    'Endereço(s)',
    'Contato(s)',
    'Termos de uso',
  ]

  const stepsReprovePartner = ['Dados do perfil', 'Endereço(s)', 'Contato(s)']

  return (
    <Container component={Box} py={3}>
      <ContentHeader
        menu="Primeiro acesso"
        title="Cadastro"
        subtitle="Primeiro acesso"
      />

      <Paper component={Box} mb={7}>
        <Box display="flex" width="100%" alignItems="center" p={2}>
          <Typography
            variant="subtitle1"
            component="h2"
            className={classes.paperHeaderTypography}
          >
            {steps[activeStep]}
          </Typography>
        </Box>
        <Divider />
        <Box p={4}>
          <Grid container>
            <Grid item md={3} xs={12}>
              <Stepper
                steps={isPartnerReproved() ? stepsReprovePartner : steps}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <Steps />
            </Grid>
          </Grid>
        </Box>
        <StepperButtons />
      </Paper>
    </Container>
  )
}

export default Onboard
