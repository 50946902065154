import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10),
  },
  content: {
    padding: theme.spacing(1, 4),
  },
  logo: {
    width: '199.00px',
    height: '75px',
    padding: '0px',
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    fontWeight: '600',
  },
  buttonText: {
    fontSize: '14px',
    color: theme.palette.white,
    fontWeight: '400',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bePartnerButton: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(1, 2),
    marginTop: '10px',
    borderRadius: '5px',
    backgroundColor: theme.palette.button.green,
    boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 50%)',
    '&:hover': {
      backgroundColor: theme.palette.button.green,
    },
  },
  linkBox: {
    display: 'inline-box',
    gap: '4px',
  },
  Link: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8',
    },
  },
}))
export default styles
