import { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { makeStyles, Box } from '@material-ui/core'

import { TopBar, Permitted } from 'components'
import { NavBar } from './components'
import styles from './styles'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const Main = ({ children, permission }) => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)

  return (
    <Permitted tag={permission} fallback={<Redirect to={routes.forbidden} />}>
      <Box className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} withSidebar />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <Box className={classes.wrapper}>
          <Box className={classes.contentContainer}>
            <Box className={classes.content}>{children}</Box>
          </Box>
        </Box>
      </Box>
    </Permitted>
  )
}

Main.propTypes = {
  children: PropTypes.any,
}

export default Main
