import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'

import { Label } from 'components'

import helpers from 'helpers'

const DetailsAddressDialog = ({
  address,
  open,
  fullScreen = false,
  setOpen = () => {},
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h5">Detalhes do endereço</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <Label title="Identificador">
                <Typography variant="body1" color="secondary">
                  {address?.id}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Tipo do endereço">
                <Typography variant="body1" color="secondary">
                  {address?.main ? 'Endereço primário' : 'Endereço secundário'}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Cidade">
                <Typography variant="body1" color="secondary">
                  {address?.city}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Estado">
                <Typography variant="body1" color="secondary">
                  {address?.state}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Rua/Logradouro">
                <Typography variant="body1" color="secondary">
                  {address?.street}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Número">
                <Typography variant="body1" color="secondary">
                  {address?.number}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Bairro">
                <Typography variant="body1" color="secondary">
                  {address?.neighborhood}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Código Postal">
                <Typography variant="body1" color="secondary">
                  {address?.postalCode}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="País">
                <Typography variant="body1" color="secondary">
                  {address?.country}
                </Typography>
              </Label>
            </Grid>
            <Grid item md={6} sm={12}>
              <Label title="Data de criação">
                <Typography variant="body1" color="secondary">
                  {helpers.formatters.date(address?.createdAt)}
                </Typography>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label title="Complemento">
                <Typography variant="body1" color="secondary">
                  {address?.complement || '-'}
                </Typography>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label title="Descrição">
                <Typography variant="body1" color="secondary">
                  {address?.description}
                </Typography>
              </Label>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box px={2} display="flex" justifyContent="flex-end" width="100%">
          <Box pl={2} pr={1}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default DetailsAddressDialog
