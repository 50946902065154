import * as yup from 'yup'

export const schema = yup.object().shape({
  companyServiceId: yup.object(),
  serviceCategory: yup.object(),
  description: yup.string().required(),
  serviceModality: yup.string().required(),
  price: yup.number(),
})

export default schema
